import DDPClient from 'ddp-client';
import createLogger from 'logging';

const logger = createLogger('ddp-client-connect');
let url = 'ws://ahcip.chs.local:5000/websocket';

if (location.hostname.match(/.+.chs.harvard.edu/) || location.hostname === 'chs.harvard.edu') {
	url = 'wss://ahcip.chs.harvard.edu/websocket';

} else if (location.hostname.match(/.+.orphe.us/)) {
	url = 'ws://ahcip.chs.orphe.us/websocket';
}

url = 'wss://ahcip.chs.harvard.edu/websocket';

const ddpclient = new DDPClient({
	url,
});

ddpclient.connect((error, wasReconnect) => {
	// If autoReconnect is true, this callback will be invoked each time
	// a server connection is re-established
	if (error) {
		logger.error(`DDP connection error: ${error}`);
		return;
	}

	if (wasReconnect) {
		logger.info('Reestablishment of DDP connection');
	}
});

export default ddpclient;
