/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './AddTooltipMenuItemButton.css';

const AddTooltipMenuItemButton = props => (
	<button
		className={cn('addTooltipMenuItemButton', props.className)}
    onClick={props.onClick}
    type="button"
	>
		{props.children}
	</button>
);

AddTooltipMenuItemButton.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	onClick: PropTypes.func,
};

export default AddTooltipMenuItemButton;
