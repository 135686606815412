/**
 * @prettier
 */

import { parseValueUrn } from './parseUrn';

const deriveLocationFromUrn = urn => {
	const { passage } = parseValueUrn(urn);

	return passage[0] || [];
};

export default deriveLocationFromUrn;
