/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import AddCommentIcon from '@material-ui/icons/AddComment';
import cn from 'classnames';
import { getVisibleSelectionRect } from 'draft-js';

const isSelectionWithinTarget = targetRef => {
	const selection = window.getSelection();

	if (!selection.rangeCount) return false;

	return targetRef.current.contains(selection.anchorNode);
};

const getTop = targetRef => {
	const selectionRect = getVisibleSelectionRect(window);
	const target = targetRef.current;
	const targetRect = target.getBoundingClientRect();

	return selectionRect.top - targetRect.top;
};

const AddCommentButton = ({ onClick, shown, targetRef = {} }) => {
	if (!targetRef.current) return null;
	if (!isSelectionWithinTarget(targetRef)) return null;

	const top = getTop(targetRef);
	const className = cn('absolute', 'z5', {
		hidden: !shown,
	});
	return (
		<div className={className} style={{ maxWidth: 200, right: 0, top: top + 2 }}>
			<div className="dim">
				<a href="#side-panel" onClick={onClick}>
					<AddCommentIcon style={{ fontSize: 32 }} />
				</a>
			</div>
		</div>
	);
};

AddCommentButton.propTypes = {
	onClick: PropTypes.func,
	shown: PropTypes.bool,
	targetRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

export default AddCommentButton;
