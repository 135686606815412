import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const query = gql`
	query tenantQuery($subdomain: String!) {
		tenantBySubdomain(subdomain: $subdomain) {
			_id
		}
	}
`;

const tenantQuery = graphql(query, {
	name: 'tenantQuery',
	options: {
		variables: {
			subdomain: 'profile',
		},
	},
});

export { tenantQuery };
