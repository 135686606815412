import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import autobind from 'react-autobind';
import Editor from 'draft-js-plugins-editor';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import createMentionPlugin from 'draft-js-mention-plugin';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';

import Utils from '../../lib/utils';
import AvatarIcon from '../AvatarIcon';
import getCurrentPathname from '../../lib/getCurrentPathname';

const inlineToolbarPlugin = createInlineToolbarPlugin();
const { InlineToolbar } = inlineToolbarPlugin;
// Keyword Mentions
const keywordMentionPlugin = createMentionPlugin(); // eslint-disable-line
// Comments Cross Reference Mentions
const commentsMentionPlugin = createMentionPlugin({ // eslint-disable-line
	mentionTrigger: '#',
});


class AnnotationCreate extends React.Component {
	constructor(props) {
		super(props);
		const userId = Cookies.get('userId');

		if (props.revision && 'text' in props.revision) {
			this.state = {
				editorState: this._createEditorState(),
				textValue: '',
			};
		} else {
			this.state = {
				editorState: EditorState.createEmpty(),
				textValue: '',
			};
		}

		autobind(this);
	}

	onTextChange = (editorState) => {
		const textHtml = stateToHTML(editorState.getCurrentContent());
		this.setState({
			editorState,
			textValue: textHtml,
		});
	}

	_createEditorState = () => {
		const { revision } = this.props;
		return EditorState.createWithContent(convertFromHTML(revision.text));
	}

	handleSave = (event) => {
		event.preventDefault();

		const { editorState } = this.state;
		const error = this.validateStateForSubmit();

		if (error.errors) {
			this.showSnackBar(error);

		} else {
			// create html from editorState's content
			const textHtml = convertToHTML({
				// perform necessary html transformations:
				entityToHTML: (entity, originalText) => { // eslint-disable-line
					// handle keyword mentions
					if (entity.type === 'mention') {
						return <a className="keyword-gloss" data-link={Utils.getEntityData(entity, 'link')}>{originalText}</a>;
					}
					// handle hashtag / commets cross reference mentions
					if (entity.type === '#mention') {
						return <a className="comment-cross-ref" href={Utils.getEntityData(entity, 'link')}><div dangerouslySetInnerHTML={{ __html: originalText }} /></a>;
					}
				},
			})(editorState.getCurrentContent());

			const textRaw = convertToRaw(editorState.getCurrentContent());
			this.saveAnnotation(
				textHtml, textRaw
			);
		}
	}

	saveAnnotation = (annotationHTML, annotationRaw) => {
		const { paragraphN, annotationId, isReply } = this.props;
		const token =	Cookies.get('loginToken');
		const tenant = this.props.tenantQuery.tenantBySubdomain;
		const userId = Cookies.get('userId');

		if (annotationId && !isReply) {
			const newRevision = {
				title: '',
				text: annotationHTML,
				textRaw: annotationRaw,
				created: new Date(),
			};

			// Add revision to set
			this.props.annotationAddRevision(
				annotationId,
				newRevision,
			);

			// TODO: handle loading state for response with errors
			//	console.error(`Error adding revision to annotation: ${err.message}`);

		} else {
			const newAnnotation = {
				status: 'publish',
				tenantId: tenant._id,
				isAnnotation: true,
				users: [userId],
				paragraphN,
				bookChapterUrl: getCurrentPathname(),
				revisions: [{
					title: '',
					text: annotationHTML,
					textRaw: annotationRaw,
					created: new Date(),
				}],
			};

			// If the annotationId is specified and isReply is true, save annotationId
			// as the parentCommentId of the annotation
			if (isReply && annotationId) {
				newAnnotation.parentCommentId = annotationId;
				if (this.props.closeReply) {
					this.props.closeReply();
				}
			}

			// Insert new annotation
			this.props.annotationCreate(newAnnotation);
		}

		// close the edit mode of the text field
		this.props.closeEditMode();
	}


	handleCancel = () => {
		this.setState({
			editorState: EditorState.createEmpty(),
			textValue: '',
		});
		this.props.handleCancel();
	}

	showSnackBar = (error) => {
		this.setState({
			snackbarOpen: error.errors,
			snackbarMessage: error.errorMessage,
		});
		setTimeout(() => {
			this.setState({
				snackbarOpen: false,
			});
		}, 4000);
	}

	validateStateForSubmit = () => {
		let errors = false;
		let errorMessage = 'Missing annotation data:';

		if (this.state.textValue === '<p><br></p>' || !this.state.textValue) {
			errors = true;
			errorMessage += ' comment text,';
		}

		if (errors === true) {
			errorMessage = errorMessage.slice(0, -1);
			errorMessage += '.';
		}

		return {
			errors,
			errorMessage,
		};
	}

	render = () => {
		const { textValue} = this.state;
		const user = this.props.getAuthedUserQuery.getAuthedUser;
		let textValueDirty = false;
		if (textValue.length && textValue !== '<p><br></p>') {
			textValueDirty = true;
		}

		if (!user) {
			return null;
		}

		return (
			<div className="annotationCreate">
				<div className="addAnnotationUser">
					<AvatarIcon
						className="avatarIcon"
						avatar={user.profile.avatarUrl}
					/>
					<span className="name">{Utils.getUserName(user)}</span>
				</div>

				<div
					className="annotationEditor"
				>
					<Editor
						editorState={this.state.editorState}
						onChange={this.onTextChange}
						placeholder="Write an annotation"
						plugins={[inlineToolbarPlugin]}
					/>
				</div>
				{textValueDirty ?
					<div className="annotationActions">
						<button
							className="annotationAction annotationActionSave"
							onClick={this.handleSave.bind(this)}
						>
							Save
						</button>
						<button
							className="annotationAction s.annotationActionCancel"
							onClick={this.handleCancel.bind(this)}
						>
							Cancel
						</button>
					</div>
				: ''}
				<InlineToolbar />
			</div>
		);
	}
}

AnnotationCreate.propTypes = {
	paragraphN: PropTypes.number,
	saveAnnotation: PropTypes.func,
	handleCancel: PropTypes.func,
	revision: PropTypes.object,
	annotationId: PropTypes.string,
};

export default AnnotationCreate;
