/**
 * @prettier
 */
import React from 'react';
import autoBind from 'react-autobind';
import cn from 'classnames';

import { RichUtils } from 'draft-js';

import { MdTitle } from 'react-icons/md';
import { MdFormatBold } from 'react-icons/md';
import { MdFormatItalic } from 'react-icons/md';
import { MdFormatQuote } from 'react-icons/md';

// components
import FormattingTooltipItemButton from '../FormattingTooltipItemButton';

import './FormattingTooltipItem.css';

class FormattingTooltipItem extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  handleClick(e) {
    const { inlineStyle, blockType } = this.props;

    e.preventDefault();

    // handle toggling block/inline type
    if (inlineStyle) {
      this._handleToggleInlineStyle();
    } else if (blockType) {
      this._handleToggleBlock();
    }
  }

  onMouseDown(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  _handleToggleInlineStyle() {
    const { editorState, inlineStyle, setEditorState } = this.props;

    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  }

  _handleToggleBlock() {
    const { editorState, blockType, setEditorState } = this.props;

    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  }

  isActive() {
    const { inlineStyle, blockType } = this.props;

    if (blockType) {
      return this.activeClassBlock();
    } else if (inlineStyle) {
      return this.activeClassInline();
    }

    return false;
  }

  activeClassInline() {
    const { editorState } = this.props;

    if (!editorState) {
      return false;
    }

    return editorState.getCurrentInlineStyle().has(this.props.inlineStyle);
  }

  activeClassBlock() {
    const { editorState, blockType } = this.props;

    if (!editorState) {
      return false;
    }

    const selection = editorState.getSelection();
    let activeBlockType = '';
    const activeBlock = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey());
    if (activeBlock) {
      activeBlockType = activeBlock.getType();
    }
    return blockType === activeBlockType;
  }

  getButtonClassName() {
    const { blockType, inlineStyle } = this.props;

    return (
      {
        blockquote: '--blockquote',
        'header-one': '--h1',
        'header-two': '--h2',
      }[blockType] ||
      {
        '--bold': 'bold',
        '--italic': 'italic',
      }[inlineStyle]
    );
  }

  getIcon() {
    return {
      'format-blockquote': MdFormatQuote,
      'format-bold': MdFormatBold,
      'format-italic': MdFormatItalic,
      'format-title': MdTitle,
    }[this.props.icon];
  }

  render() {
    const Icon = this.getIcon();
    const className = cn('formattingTooltipItem', this.getButtonClassName(), {
      active: this.isActive(),
    });

    return (
      <FormattingTooltipItemButton
        className={className}
        onClick={this.handleClick}
        onMouseDown={this.onMouseDown}
      >
        <Icon className={className} />
      </FormattingTooltipItemButton>
    );
  }
}

export default FormattingTooltipItem;
