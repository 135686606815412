import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const query = gql`
query usersQuery {
	users {
    _id
    username
    isAnnotator
    emails
    services
    subscriptions
    roles
		profile {
			name
			avatarUrl
		}
    canEditCommenters
    bookmarks
    canAnnotateBooks
    authorOfBooks
    highlightingPreference
    recentPositions
    createdAt
    createdBy
    updatedAt
    updatedBy
	}
}
`;

const queryPublicById = gql`
query queryPublicById($id: String) {
  userGetPublicById(_id: $id) {
    _id
    username
    isAnnotator
    emails
    services
    subscriptions
    roles
		profile {
			name
			avatarUrl
		}
    canEditCommenters
    bookmarks
    canAnnotateBooks
    authorOfBooks
    highlightingPreference
    recentPositions
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
`;

const queryUsersPublicById = gql`
query queryUsersPublicById($userIds: [String]) {
  usersGetPublicById(userIds: $userIds) {
    _id
    username
    isAnnotator
    emails
    services
    subscriptions
    roles
		profile {
			name
			avatarUrl
		}
    canEditCommenters
    bookmarks
    canAnnotateBooks
    authorOfBooks
    highlightingPreference
    recentPositions
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
`;

const getAuthedUser = gql`
query getAuthedUserQuery {
	getAuthedUser {
    _id
    username
    isAnnotator
    emails
    services
    subscriptions
    roles
		profile {
			name
			avatarUrl
		}
    canEditCommenters
    bookmarks
    canAnnotateBooks
    authorOfBooks
    highlightingPreference
    recentPositions
    createdAt
    createdBy
    updatedAt
    updatedBy
	}
}
`;


const usersQuery = graphql(query, {
	name: 'usersQuery'
});

const userGetPublicById = graphql(queryPublicById, {
	options: props => {
		return {
			variables: {
				id: props.userId,
			},
		};
	},
	name: 'userGetPublicById'
});

const usersGetPublicById = graphql(queryUsersPublicById, {
	options: props => {
		return {
			variables: {
				userIds: props.userIds,
			},
		};
	},
	name: 'usersGetPublicById'
});

const getAuthedUserQuery = graphql(getAuthedUser, {
	name: 'getAuthedUserQuery'
});

export { usersQuery, userGetPublicById, usersGetPublicById, getAuthedUserQuery };
