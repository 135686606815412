import React from 'react';
import PropTypes from 'prop-types';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import $ from 'jquery';

import muiTheme from '../../lib/muiTheme';

class Publications extends React.Component {
	static propTypes = {
		settings: PropTypes.object,
	}

	constructor(props) {
		super(props);
		this.state = {open: false};
	}

	getChildContext = () => {
		return { muiTheme: getMuiTheme(muiTheme) };
	}

	componentDidMount = () => {
		// For some reason the animation needs a timeout else the styles are added before the component is properly mounted.
		setTimeout(() => {
			$("[class*='headerBlock']").addClass('showHeaderBlock');
			$("[class*='list']").addClass('showList');
		}, 10);
	}

	render = () => {
		const settings = this.props.settingQueryByTenantId.settingPublic;

		const style = {
			whiteSpace: 'initial',		// ensures longer lines are wrapped
			padding: '12px 0px',
			lineHeight: '26px',
			minHeight: 'inherit'
		};

		if (!settings) {
			return null;
		}

		return (
			<div className="publications">
				<div className="headerBlock">
					<div>Digital Publications</div>
					<i>{settings.title}</i>
				</div>
				<div className="list">
					<Menu>
						<MenuItem
							style={style}
							href="http://chs.harvard.edu/CHS/article/display/1165?menuId=249"
							primaryText="Hellenic Studies Series"
						/>
						<MenuItem
							style={style}
							href="http://chs.harvard.edu/CHS/article/display/41?menuId=248"
							primaryText="Curated Books"
						/>
						<MenuItem
							style={style}
							href="http://chs.harvard.edu/CHS/article/display/42?menuId=247"
							primaryText="Curated Articles"
						/>
						<MenuItem
							style={style}
							href="http://chs.harvard.edu/CHS/article/display/37?menuId=246"
							primaryText="Primary Texts"
						/>
						<MenuItem
							style={style}
							href="http://chs.harvard.edu/CHS/article/display/1167?menuId=66"
							primaryText="Classics@ Journal"
							target="_blank"
							rel="noopener noreferrer"
						/>
						<MenuItem
							style={style}
							href="https://classical-inquiries.chs.harvard.edu/"
							target="_blank"
							rel="noopener noreferrer"
							primaryText="Classical Inquiries"
						/>
						<MenuItem
							style={style}
							href="http://www.chs-fellows.org/"
							target="_blank"
							rel="noopener noreferrer"
							primaryText="CHS Research Bulletin"
						/>
						<MenuItem
							style={style}
							href="http://chs.harvard.edu/CHS/article/display/5417?menuId=134"
							primaryText="Digital Humanities Projects"
						/>
						<MenuItem
							style={style}
							href="http://chs.harvard.edu/CHS/article/display/1171?menuId=64"
							primaryText="Prospective Authors"
						/>
					</Menu>
				</div>
			</div>
		);
	}
}

Publications.childContextTypes = {
	muiTheme: PropTypes.object,
};

export default Publications;
