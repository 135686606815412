import { CompositeDecorator } from 'draft-js';

import { Gloss, findGlossEntities } from './Gloss';
import { Image, findImageEntities } from './Image';
import { Link, findLinkEntities } from './Link';


const decorators = new CompositeDecorator([
	{
		strategy: findLinkEntities,
		component: Link,
	},
	{
		strategy: findGlossEntities,
		component: Gloss,
	},
	{
		strategy: findImageEntities,
		component: Image,
	}
]);

export default decorators;
