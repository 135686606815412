import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const query = gql`
	query settingQuery ($tenantId: String) {
		settingPublic(tenantId: $tenantId) {
			_id
		}
	}
`;

const settingQueryByTenantId = graphql(query, {
	name: 'settingQueryByTenantId',
	options: params => ({
		variables: {
			tenantId: params.tenantId,
		},
	}),
});

export { settingQueryByTenantId };
