/**
 * @prettier
 */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import editionsAndTranslationsQuery from '../../../graphql/queries/editionsAndTranslations';

import {
	makeStyles,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from '@material-ui/core';

const renderProject = (classes) => {
	const project = JSON.parse(document.getElementById('project').textContent);
	const { id, title } = project;
	return (
		<MenuItem
			className={classes.item}
			key={id}
			value="@@reading-env/project-commentary"
		>
			<Typography noWrap>{title}</Typography>
		</MenuItem>
	);
};

const renderWorks = (classes, works, workType = 'exemplar') =>
	works.map(w => {
		const { id, title } = w[workType];

		return (
			<MenuItem className={classes.item} key={id} value={w.id}>
				<Typography noWrap>{title}</Typography>
			</MenuItem>
		);
	});

const useStyles = makeStyles(theme => ({
	button: {
		margin: theme.spacing(1),
	},
	formControl: {
		display: 'flex',
		margin: theme.spacing(1),
		width: '100%',
	},
	group: {
		fontWeight: theme.typography.fontWeightMedium,
		opacity: 1,
	},
	item: {
		paddingLeft: 3 * theme.spacing(1),
		maxWidth: 600,
	},
}));

const SidePanelHeader = ({ currentSelection, setCurrentSelection, urn }) => {
	const classes = useStyles();
	const inputLabel = useRef({});
	const formControl = useRef({});
	const [labelWidth, setLabelWidth] = useState(100);
	const { loading, error, data } = useQuery(editionsAndTranslationsQuery, {
		variables: { urn },
	});

	useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth);
	}, []);

	const onChange = e => setCurrentSelection(e.target.value);

	if (loading) {
		return <FormControl className={classes.formControl} variant="outlined" />;
	}

	if (error) {
		return (
			<FormControl className={classes.formControl} variant="outlined">
				{error.graphQLErrors.map(({ message }, i) => (
					<FormHelperText key={i}>{message}</FormHelperText>
				))}
			</FormControl>
		);
	}

	const { works: works } = data;
	const { editions, translations, versions } = works.reduce(
		(ws, w) => {
			if (w.exemplar) {
				ws.editions.push(w);
			} else if (w.translation) {
				ws.translations.push(w);
			} else if (w.version) {
				ws.versions.push(w);
			}

			return ws;
		},
		{ editions: [], translations: [], versions: [] }
	);

	return (
		<FormControl
			className={classes.formControl}
			ref={formControl}
			variant="filled"
		>
			<InputLabel
				htmlFor="textInfo"
				ref={inputLabel}
				id="@@reading-env/text-select-label"
			>
				Editions and translations
			</InputLabel>
			<Select
				inputProps={{ name: 'text-info', id: 'textInfo' }}
				labelId="@@reading-env/text-select-label"
				labelWidth={labelWidth}
				onChange={onChange}
				value={currentSelection}
				variant="filled"
			>
				<MenuItem className={classes.item} value="@@reading-env/about-the-text">
					About the text
				</MenuItem>
				{/* renderCommentaries(classes, commentaries) */}
				{(editions.length > 0 || versions.length > 0) && (
					<MenuItem className={classes.group} disabled>
						Editions
					</MenuItem>
				)}
				{renderWorks(classes, editions, 'exemplar')}
				{renderWorks(classes, versions, 'version')}
				<MenuItem className={classes.group} disabled>
					Translations
				</MenuItem>
				{translations.length > 0}
				{renderWorks(classes, translations, 'translation')}
				<MenuItem
					className={classes.item}
					value="@@reading-env/userText-translation"
				>
					Commentary translation
				</MenuItem>
				<MenuItem className={classes.group} disabled>
					Commentaries
				</MenuItem>
				{renderProject(classes, project)}
			</Select>
		</FormControl>
	);
};

SidePanelHeader.propTypes = {
	currentSelection: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.array,
		PropTypes.number,
	]),
	setCurrentSelection: PropTypes.func,
	urn: PropTypes.string,
};

export default SidePanelHeader;
