/**
 * @prettier
 */

import React from 'react';
import {renderCommentsIcon} from '../components/ReadingEnv/TextNode';

const handleClick = (cb, urn) => e => {
	e.preventDefault();

	cb(urn, `Commenting on ${urn}:`);
};

const getHeadings = (
	location,
	prevLocation,
	refsDecls,
	urn = '',
	commentCounts = {},
	cb = () => {}
) => {
	let heading, subheading, subsubheading;

	const showCommentsForNode = _e => {
		cb(urn);
	};

	if (location.length > 1) {
		// Only compare up to the second-most granular location,
		// e.g., if we have [book, chapter, line], only
		// check if "book" (h1) and "chapter" (h2) have changed.
		const label = (refsDecls[0] && refsDecls[0].label) || '';
		const textNodeCommentCount = commentCounts[location[0]] || 0;
		heading =
			location[0] !== prevLocation[0] ? (
				<div className="heading">
					<h1
						className="serif"
						onClick={handleClick(cb, `${urn}:${location[0]}`)}
						style={{ textTransform: 'capitalize' }}
					>
						{label} {location[0]}
					</h1>
					<div className='ease-in-out fw7 pointer unselectable commentButton'>
						{renderCommentsIcon(textNodeCommentCount, showCommentsForNode)}
					</div>
				</div>
			) : null;

		if (location.length > 2) {
			const textNodeCommentCount = commentCounts[`${location[0]}.${[1]}`] || 0;
			subheading =
				location[1] !== prevLocation[1] ? (
					<div className="heading">
						<h2
							className="serif"
							onClick={handleClick(cb, `${urn}:${location[0]}.${location[1]}`)}
							style={{ textTransform: 'capitalize' }}
						>
							{location[0]}.{location[1]}
						</h2>
						<div className='ease-in-out fw7 pointer unselectable commentButton'>
							{renderCommentsIcon(textNodeCommentCount, showCommentsForNode)}
						</div>
					</div>
				) : null;

			if (location.length > 3) {
				const textNodeCommentCount = commentCounts[`${location[0]}.${location[1]}.${location[2]}`] || 0;
				subsubheading =
					location[2] !== prevLocation[2] ? (
						<div className="heading">
							<h3
								className="serif"
								onClick={handleClick(
									cb,
									`${urn}:${location[0]}.${location[1]}.${location[2]}`
								)}
								style={{ textTransform: 'capitalize' }}
							>
								{location[0]}.{location[1]}.{location[2]}
							</h3>
							<div className='ease-in-out fw7 pointer unselectable commentButton'>
								{renderCommentsIcon(textNodeCommentCount, showCommentsForNode)}
							</div>
						</div>
					) : null;
			}
		}
	}

	return { heading, subheading, subsubheading };
};

export default getHeadings;
