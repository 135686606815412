/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import S3Upload from 'react-s3-uploader/s3upload';
import autoBind from 'react-autobind';
import shortid from 'shortid';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';

// redux
import editorActions from '../../../../../actions';

// components
import AddTooltipMenuItemButton from '../../AddTooltipMenuItemButton';

// lib
import initializeFileReader from '../../../../../lib/initializeFileReader';
import insertEntity from '../../../../../lib/insertEntity';

// icons
import { MdImage } from 'react-icons/md';

class AddImageButton extends React.Component {
  static propTypes = {
    addTooltip: PropTypes.object.isRequired,
    editorState: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    setAddTooltip: PropTypes.func.isRequired,
    setEditorState: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.fileInputRef = React.createRef();
    this.fileReader = initializeFileReader();

    autoBind(this);
  }

  handleAddImage() {
    this.fileInputRef.current.click();
  }

  handleFileInput(e) {
    const { files } = e.target;
    const file = files[0];

    this.fileReader.onload = e =>
      new S3Upload({
        contentDisposition: 'auto',
        fileElement: { files },
        onError: this.handleUploadError,
        onFinishS3Put: this.handleUploadFinish,
        onProgress: this.handleUploadProgress,
        s3path: '',
        server: process.env.REACT_APP_SERVER,
        signingUrl: '/s3/sign',
        scrubFilename: filename => {
          const secureFilename = filename.replace(/[^\w\d_\-\.]+/gi, ''); // eslint-disable-line
          return `${shortid.generate()}-${secureFilename}`;
        },
        signingUrlMethod: 'GET',
        signingUrlWithCredentials: true,
        uploadRequestHeaders: {
          'x-amz-acl': 'public-read',
        },
      });

    try {
      this.fileReader.readAsDataURL(file);
    } catch (e) {
      this.handleUploadError(e);
    }
  }

  handleUploadError(e) {
    console.error(`Error uploading file: ${e.message}`);

    const { enqueueSnackbar, setAddTooltip } = this.props;

    setAddTooltip({
      visible: false,
      menuVisible: false,
    });

    enqueueSnackbar("Sorry, we weren't able to upload that image.", {
      variant: 'error',
    });
  }

  handleUploadProgress() {}

  handleUploadFinish(event) {
    const {
      addTooltip,
      editorState,
      setAddTooltip,
      setEditorState,
    } = this.props;

    const newEditorState = insertEntity(editorState, {
      entityType: 'IMAGE',
      entityData: {
        src: `https://iiif.orphe.us/${event.filename}/full/300,/0/default.jpg`,
      },
    });

    setEditorState(newEditorState);

    // wait a tick before hiding the menu
    setTimeout(() => {
      setAddTooltip({
        ...addTooltip,
        visible: false,
        menuVisible: false,
      });
    }, 0);
  }

  render() {
    return (
      <AddTooltipMenuItemButton onClick={this.handleAddImage}>
        <MdImage />
        <span>Image</span>
        <input
          type="file"
          style={{ display: 'none' }}
          ref={this.fileInputRef}
          onChange={this.handleFileInput}
        />
      </AddTooltipMenuItemButton>
    );
  }
}

const mapStateToProps = state => ({
  ...state.editor,
});

const mapDispatchToProps = {
  setAddTooltip: editorActions.setAddTooltip,
  setEditorState: editorActions.setEditorState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(AddImageButton));
