import React from 'react';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import autobind from 'react-autobind';

import { recaptcha } from '../../../lib/config';
import RecaptchaFormsyInput from '../RecaptchaFormsyInput';
import OAuthButtons from '../OAuthButtons';

class SignIn extends React.Component {

	static propTypes = {
		openUserModal: PropTypes.func.isRequired,
		signInMethod: PropTypes.func.isRequired,
		errors: PropTypes.array.isRequired,
	}

	constructor(props) {
		super(props);

		this.state = {
			canSubmit: true,
			data: null,
			email: '',
			password: '',
		};

		autobind(this);
	}

	_enableButton = () => {
		this.setState({
			canSubmit: true,
		});
	}

	_disableButton = () => {
		this.setState({
			canSubmit: false,
		});
	}

	_submitForm = (e) => {
		const { signInMethod } = this.props;
		const data = {
			email: this.state.email,
			password: this.state.password,
		};

		e.preventDefault();

		// hande form submit with valid data
		// execute signInMethod passed from props
		// pass data to method attr
		// sing in method: password
		signInMethod(data, 'password');
	}

	handleChangeEmail = (e) => {
		this.setState({
			email: e.target.value.trim(),
		});
	}

	handleChangePassword= (e) => {
		this.setState({
			password: e.target.value.trim(),
		});
	}

	renderErrors = () => {
		const { errors } = this.props;

		return (
			<div className="account-modal-errors">
				{errors.map((error, i) => {
					let errorMessage = error.message;

					if (error.message === 'error.accounts.Login forbidden [403]') {
						errorMessage = 'Email address or password is incorrect';
					}

					console.error(error);

					return (
						<span
							key={i}
							className="account-modal-errors--message"
						>
							{errorMessage}
						</span>
					);
				})}
			</div>
		);
	}

	render = () => {
		const { openUserModal, signInMethod, errors } = this.props;
		const emailError = 'Email incorrect';
		const passwordError = 'Password incorrect';

		return (
			<div className="modalAuthForm signIn">
				<form
					onSubmit={this._submitForm}
				>
					<br />
					<br />
					<h3 className="at-title modalAuthTitle">Sign In</h3>
					{/*
					<OAuthButtons
						handleFacebook={signInMethod.bind(null, null, 'facebook')}
						handleGoogle={signInMethod.bind(null, null, 'google')}
						handleTwitter={signInMethod.bind(null, null, 'twitter')}
					/>
					<div className="at-sep">
						<strong>OR</strong>
					</div>
					*/}
					<div className="atPwdForm">
						{this.renderErrors()}
						<input
							name="email"
							placeholder="Email"
							validations="isEmail"
							validationError={emailError}
							required
							hintText="name@example.com"
							floatingLabelText="Email"
							fullWidth
							onChange={this.handleChangeEmail}
						/>
						<input
							name="password"
							placeholder="Password"
							validationError={passwordError}
							required
							hintText="password"
							floatingLabelText="Password"
							type="password"
							fullWidth
							onChange={this.handleChangePassword}
						/>
						<RaisedButton
							type="submit"
							label="Sign In"
							disabled={!this.state.canSubmit}
							className="submitButton"
							style={{
								height: '50px',
							}}
							primary
							fullWidth
						/>
					</div>
					<div className="register">
						Don&apos;t have an account?&nbsp;
						<a
							onTouchTap={openUserModal.bind(this, false)}
						>
							Register
						</a>
					</div>
				</form>
			</div>
		);
	}
}

export default SignIn;
