/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { convertToRaw } from 'draft-js';

import CHSEditor from '../../../../orpheus-editor/components/Editor';

import decorators from '../../../../orpheus-editor/components/decorators';

import createBaseEditorState from '../../../lib/createBaseEditorState';
import makeRequest from '../../../lib/makeRequest';

const _createUserText = async userText => {
	return await makeRequest('usertexts/', {
		body: JSON.stringify(userText),
		method: 'POST',
	});
};

const _updateUserText = async userText => {
	return await makeRequest(`usertexts/${userText.id}/`, {
		body: JSON.stringify(userText),
		method: 'PUT',
	});
};

function TranslationForm({
	hideTranslationForm,
	refetchTranslations = () => {},
	textForTranslation = '',
	translation = {},
	urn,
}) {
	// https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
	const revision =
		translation.revisions && translation.revisions.length
			? translation.revisions[0]
			: {};
	const [localContent, setLocalContent] = useState(
		createBaseEditorState(revision, decorators)
	);
	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	useEffect(() => {
		if (success) {
			hideTranslationForm();
		}

		if (error) {
			setSubmitting(false);
		}
	}, [error, success]);

	const createTranslation = rawContent => {
		const revision = {
			text: rawContent,
		};

		const userText = {
			content_type: 'TRANSLATION',
			revisions: [revision],
			urn,
		};

		return _createUserText(userText);
	};

	const updateTranslation = rawContent => {
		const currentTranslation = Object.assign({}, translation);
		const now = new Date();

		currentTranslation.revisions = currentTranslation.revisions || [];
		currentTranslation.revisions.push({
			created_at: now,
			text: rawContent,
			updated_at: now,
		});

		return _updateUserText(currentTranslation);
	};

	const handleSubmit = async e => {
		e.preventDefault();

		setSubmitting(true);

		let content;
		try {
			content = JSON.stringify(convertToRaw(localContent.getCurrentContent()));
		} catch (e) {
			console.error('Could not convert content to raw. Reason:', e);
			return;
		}

		// if the translation has already been saved on the
		// server, update it
		const result = Boolean(translation.id)
			? await updateTranslation(content)
			: await createTranslation(content);

		if (Boolean(result)) {
			setSubmitting(false);
			setSuccess(true);
			setLocalContent(result);
			refetchTranslations();
		} else {
			setError(
				new Error('Something went wrong committing that translation, sorry.')
			);
		}
	};

	return (
		<div className="p1">
			<h3>
				{Boolean(translation._id) ? 'Edit Translation' : 'Create Translation'}
			</h3>
			<p className="gray focusText unselectable">{textForTranslation}</p>
			{error && <p className="error">{error.toString()}</p>}
			<form onSubmit={handleSubmit}>
				<div className="mb2">
					<CHSEditor
						editorState={localContent}
						handleChange={setLocalContent}
					/>
				</div>
				<div className="sidebarFormActionButtons">
					<Button
						variant="contained"
						color="primary"
						onClick={handleSubmit}
						disabled={submitting}
					>
						{submitting ? ' Saving...' : 'Save'}
					</Button>
					<Button onClick={hideTranslationForm}>Cancel</Button>
				</div>
			</form>
		</div>
	);
}

TranslationForm.propTypes = {
	hideTranslationForm: PropTypes.func,
	refetchTranslations: PropTypes.func,
	textForTranslation: PropTypes.string,
	translation: PropTypes.shape({
		id: PropTypes.number,
	}),
	urn: PropTypes.string,
};

export default TranslationForm;
