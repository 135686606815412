/**
 * @prettier
 */

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// import { useQuery } from '@apollo/react-hooks';

import Reader from './ReadingEnv/Reader';
import SidePanel from './ReadingEnv/SidePanel';
import TextNode, {renderCommentsIcon} from './ReadingEnv/TextNode';
import NotFound from '../../../components/common/NotFound'

// import APP_INIT_QUERY from './App.graphql';

import { parseValueUrn as parseUrn } from '../lib/parseUrn';
import canEditComments from '../lib/canEditComments';
import getCitationFromURN from '../lib/getCitationFromURN';
import getCurrentLocationSpan from '../lib/getCurrentLocationSpan';
import getHeadings from '../lib/getHeadings';
import makeRequest from '../lib/makeRequest';
import updateUrl from '../lib/updateUrlWithPassageRange';
import bookOfSecrets, { bookOfSecretsPcss } from '../lib/bookOfSecrets';

const textGroupUrn = (urn = '') => (urn.split('.') || [])[0];
const withoutPassageCitation = (urn = '') =>
	urn
		.split(':')
		.slice(0, 4)
		.join(':');
const createUrnSearchParam = (work = {}) => {
	if (!work || !work.textNodes) {
		return '';
	}

	const [start, end] = getCurrentLocationSpan(work.textNodes);

	return `${work.full_urn}:${start}-${end}`;
};

const App = ({ urn  }) => {
	const [commentFormOpen, setCommentFormOpen] = useState(false);
	const [currentComment, setCurrentComment] = useState({});
	const [currentTranslation, setCurrentTranslation] = useState({});
	const [selectionText, setSelectionText] = useState('');
	const [selectionUrn, setSelectionUrn] = useState('');
	const [sidePanelOpen, setSidePanelOpen] = useState(false);
	const [translationFormOpen, setTranslationFormOpen] = useState(false);

	const { passage } = parseUrn(urn);
	let startsAtLocation = null;
	let endsAtLocation = null;
	if (passage && passage.length) {
		startsAtLocation = passage[0];
		if (passage.length > 1) {
			endsAtLocation = passage[1];
		}
	}

	/**
	const { loading, error, data = {} } = useQuery(APP_INIT_QUERY, {
		variables: {
			endsAtLocation,
			fullUrn: urn,
			fullUrnWithoutPassageCitation: withoutPassageCitation(urn),
			startsAtLocation,
			textGroupUrn: textGroupUrn(urn),
		},
	});
	*/
	const { loading, error, data } = { loading: false, error: false, data: {}};

	const [commentCounts, setCommentCounts] = useState({});
	const [commentCountsError, setCommentCountsError] = useState(null);
	const [userTextsLoading, setUserTextsLoading] = useState(true);
	const [userTexts, setUserTexts] = useState([]);
	const [language, setLanguage] = useState('');

	const getCommentCountsForUrn = useCallback(
		async (urnSearch = urn) => {
			const data = await makeRequest(
				`comments/counts_by_urn/?urn_search=${urnSearch}`
			);

			if (data) {
				setCommentCounts(data.results);
			} else {
				setCommentCountsError('There was an error fetching comments');
			}
		},
		[urn]
	);

	async function getUserTextsForUrn(urnSearch = urn) {
		setUserTextsLoading(true);

		const data = await makeRequest(`usertexts/?urn_search=${urnSearch}`);

		if (data) {
			setUserTexts(data.results);
		} else {
			console.error('There was an error fetching userTexts');
		}

		setUserTextsLoading(false);
	}

	useEffect(() => {
		let { workByUrn: work } = data || {};

		if (window.location.pathname.indexOf('urn:cts:dhLab:flamel.bookOfSecrets.transcriptionNewton.pcss:') >= 0) {
			work = bookOfSecretsPcss.work;
		} else if (window.location.pathname.indexOf('urn:cts:dhLab:flamel.bookOfSecrets.transcriptionNewton:') >= 0) {
			work = bookOfSecrets.work;
		} else if (window.location.pathname.indexOf("/part-i-introduction-to-homeric-poetry/") >= 0) {
			work = bookOfSecrets.work;
		}

		if (work && work.textNodes) {
			// set language for ltr or rtl
			if (work.language && work.language.title) {
				setLanguage(work.language.title.toLowerCase());
			}

			const [start, end] = getCurrentLocationSpan(work.textNodes);
			updateUrl(start, end, work.full_urn);

			const urnSearch = createUrnSearchParam(work);

			getCommentCountsForUrn(urnSearch);
			getUserTextsForUrn(urnSearch);

		}
	}, [createUrnSearchParam(window.location.hostname.indexOf('homeric-poetry') >= 0 ? bookOfSecrets.work : data.workByUrn)]);

	const closeCommentForm = _e => setCommentFormOpen(false);
	const hideTranslationForm = _e => setTranslationFormOpen(false);
	const openCommentForm = _e => {
		hideTranslationForm();
		setCommentFormOpen(true);
	};
	const openTranslationForm = _e => {
		closeCommentForm();
		setTranslationFormOpen(true);
	};
	const openSidePanel = _e => setSidePanelOpen(true);
	const openSidePanelForUrn = (urn, text = '') => {
		setSelectionUrn(urn);
		setSidePanelOpen(true);

		if (text) {
			setSelectionText(text);
			openCommentForm();
		}
	};
	const openSidePanelForTranslation = (urn, text = '') => {
		setSelectionUrn(urn);
		setSidePanelOpen(true);
		setCurrentTranslation(
			userTexts
				.filter(u => u.content_type === 'TRANSLATION')
				.find(u => u.urn === urn) || {}
		);

		if (text) {
			setSelectionText(text);
			openTranslationForm();
		}
	};
	const toggleSidePanel = _e => {
		setSidePanelOpen(!sidePanelOpen);
		// TODO: Set commentary as default when opening the sidebar with no comment
		// or translation selected
		if (!currentComment && !currentTranslation) {
		}
	}

	if (loading) {
		return (
			<div className="ReadingEnv">
				<div className="readingEnvLoadingContent">
					<div className="readingEnvLoadingHeader" />
					<div className="readingEnvLoadingLine" />
					<div className="readingEnvLoadingLine" />
					<div className="readingEnvLoadingLine" />
					<div className="readingEnvLoadingLine" />
					<div className="readingEnvLoadingLine" />
				</div>
			</div>
		);
	}

	if (error) {
		return (
			<div>
				<span className="error">We encountered an error: {error.message}</span>
			</div>
		);
	}

	// 404
	if (!data.workByUrn) {
		return (
			<div>
				<NotFound />
			</div>
		)
	}

	if (userTextsLoading) {
		return (
			<div className="ReadingEnv">
				<div className="readingEnvLoadingContent">
					<div className="readingEnvLoadingHeader" />
					<div className="readingEnvLoadingLine" />
					<div className="readingEnvLoadingLine" />
					<div className="readingEnvLoadingLine" />
					<div className="readingEnvLoadingLine" />
					<div className="readingEnvLoadingLine" />
				</div>
			</div>
		);
	}


	if (commentCountsError) {
		console.error(commentCountsError);
	}

	let { workByUrn: work = {}, textGroups: textGroups = [] } = data;

	if (window.location.pathname.indexOf('urn:cts:dhLab:flamel.bookOfSecrets.transcriptionNewton.pcss:') >= 0) {
		work = bookOfSecretsPcss.work;
	} else if (window.location.pathname.indexOf('urn:cts:dhLab:flamel.bookOfSecrets.transcriptionNewton:') >= 0) {
		work = bookOfSecrets.work;
	} else if (window.location.pathname.indexOf('homeric-poetry') >= 0) {
		work = bookOfSecrets.work;
	}

	const textGroup = textGroups[0];

	const { refsDecls } = work;
	const workAndTextGroup = { work, textGroup };
	const textNodes = (work || {}).textNodes || [];
	const userTextEditions = userTexts.filter(
		ut => ut.content_type === 'EDITION'
	);

	const classNames = {
		sidePanelVisible: sidePanelOpen,
	}

	classNames[language] = true;

	const classes = classnames('ReadingEnv', classNames);

	return (
		<div className={classes}>
			<Reader
				openSidePanelForUrn={openSidePanelForUrn}
				sidePanelOpen={sidePanelOpen}
				text={workAndTextGroup}
				textNodes={textNodes}
				urn={urn}
			>
				{textNodes.map((textNode, i) => {
					const prevNode = textNodes[i - 1];
					const prevLocation = (prevNode && prevNode.location) || [];
					const { location, urn: textNodeUrn } = textNode;
					const textNodeCommentCount = commentCounts[location.join('.')] || 0;

					let authedOpenSidePanelForUrn;
					if (canEditComments()) {
						authedOpenSidePanelForUrn = openSidePanelForUrn;
					}

					const { heading, subheading, subsubheading } = getHeadings(
						location,
						prevLocation,
						refsDecls,
						textNodeUrn.slice(0, textNodeUrn.lastIndexOf(':')),
						commentCounts,
						authedOpenSidePanelForUrn,
					);
					const userText =
						(userTexts.find(
								ut =>
									getCitationFromURN(ut.urn) === getCitationFromURN(textNodeUrn)
							)) ||
						{};

					return (
						<div key={`div-${textNode.urn}-${textNode.location}`}>
							{heading}
							{subheading}
							{subsubheading}
							<TextNode
								commentCount={textNodeCommentCount}
								hideInteractiveButtons={!canEditComments()}
								openSidePanel={openSidePanel}
								openSidePanelForUrn={openSidePanelForUrn}
								openSidePanelForTranslation={openSidePanelForTranslation}
								textNode={textNode}
								urn={urn}
								userText={userText}
							/>
						</div>
					);
				})}
			</Reader>
			<SidePanel
				currentComment={currentComment}
				currentTranslation={currentTranslation}
				hideCommentForm={closeCommentForm}
				hideTranslationForm={hideTranslationForm}
				openCommentForm={openCommentForm}
				refetchComments={getCommentCountsForUrn}
				refetchTranslations={getUserTextsForUrn}
				selectionText={selectionText}
				selectionUrn={selectionUrn}
				setCurrentComment={setCurrentComment}
				setCurrentTranslation={setCurrentTranslation}
				showCommentForm={commentFormOpen}
				showTranslationForm={translationFormOpen}
				sidePanelOpen={sidePanelOpen}
				text={workAndTextGroup}
				textNodes={textNodes}
				toggleSidePanel={toggleSidePanel}
			/>
		</div>
	);
};

App.propTypes = {
	urn: PropTypes.string.isRequired,
};

export default App;
