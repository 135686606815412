/**
 * @prettier
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';

import { EditorState, RichUtils, SelectionState } from 'draft-js';

import removeLinkEntity from '../../../../lib/removeLinkEntity';

import './LinkTextInput.css';

const ENTER = 13;
const ESC = 27;

const cleanUrl = s => {
  if (!/^https?/.test(s)) {
    return `https://${s}`;
  }

  return s;
};

const clickedOnLinkInput = evt => {
  let node = evt.target.parentNode;
  let className = null;

  while (!className && node) {
    className = node.className;
    node = node.parentNode;
  }

  // NOTE: (charles) These are possible className targets for
  // the user to click, after which clicks we don't want to
  // to hide the tooltip. There is probably a better way to
  // handle these clicks, but because the DOM contents of
  // the tooltip change, it was difficult to find a target
  // node for reliably canceling the hiding function
  return ['linkTextInput', 'formattingTooltip'].some(
    s => String(className).indexOf(s) > -1
  );
};

const createLink = (editorState, url) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
    url,
  });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });
  const selection = newEditorState.getSelection();
  const linkedEditorState = RichUtils.toggleLink(
    newEditorState,
    selection,
    entityKey
  );
  return EditorState.acceptSelection(
    linkedEditorState,
    SelectionState.createEmpty().merge({
      anchorKey: selection.getEndKey(),
      anchorOffset: selection.getEndOffset(),
      isBackward: false,
    })
  );
};

class LinkTextInput extends React.Component {
  static propTypes = {
    editorRef: PropTypes.object.isRequired,
    editorState: PropTypes.object.isRequired,
    overrideContent: PropTypes.func.isRequired,
    setEditorState: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    autoBind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress, false);
    window.addEventListener('click', this.handleWindowClick);
  }

  componentDidUpdate() {
    this.inputRef.current.focus();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress, false);
    window.removeEventListener('click', this.handleWindowClick);
  }

  handleWindowClick(e) {
    if (!clickedOnLinkInput(e)) {
      e.preventDefault();

      this.disableLinkMode();
    }
  }

  handleKeyPress(e) {
    if (e.which === ENTER) {
      e.preventDefault();
      this.confirmLink(e);
      return;
    }

    if (e.which === ESC) {
      e.preventDefault();
      this.disableLinkMode();
      // return focus to Editor so that the FormattingTooltip
      // shows up
      this.props.editorRef.current.focus();
      return;
    }
  }

  disableLinkMode() {
    this.props.overrideContent(null);
  }

  confirmLink(e) {
    const { editorRef, editorState, setEditorState } = this.props;
    const url = e.target.value;
    const nextEditorState = url
      ? createLink(editorState, cleanUrl(url))
      : removeLinkEntity(editorState);

    setEditorState(nextEditorState);

    // give it a tick to update
    setTimeout(editorRef.current.focus, 0);

    this.disableLinkMode();
  }

  onMouseDown(e) {
    e.preventDefault();
    e.stopPropagation();

    this.inputRef.current.focus();
  }

  render() {
    const url = this._getUrl() || '';
    return (
      <div className="linkTextInput" onMouseDown={this.onMouseDown}>
        <input
          autoFocus
          placeholder="Paste or type a link..."
          onKeyPress={this.handleKeyPress}
          ref={this.inputRef}
          defaultValue={url}
        />
        <i className="mdi mdi-close" onClick={this.disableLinkMode} />
      </div>
    );
  }

  _getUrl() {
    const { editorState } = this.props;
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const key = selection.getStartKey();
    const block = content.getBlockForKey(key);
    const entityKey = block.getEntityAt(selection.getStartOffset());

    if (entityKey) {
      const entity = content.getEntity(entityKey);
      const { url } = entity.getData();

      return url;
    }

    return null;
  }
}

export default LinkTextInput;
