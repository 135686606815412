/**
 * @prettier
 */

export const URN_REGEX = /\burn:[a-z0-9][a-z0-9-]{0,31}:[a-z0-9()+,\-.:=@;$_!*'%/?#]+/i;

export default function getUrnFromUrl() {
	const maybeUrn = window.location.pathname.split('/')[2].split(/\?|\#/)[0];

	if (maybeUrn.match(URN_REGEX)) {
		return maybeUrn;
	}

	return null;
}
