import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import getCurrentPathname from '../../lib/getCurrentPathname';

const query = gql`
	query bookQuery ($chapterUrl: String!){
	  bookByChapter(chapterUrl: $chapterUrl) {
			_id
			title
			author
			chapters
	  }
	}
`;


const bookQuery = graphql(query, {
	name: 'bookQuery',
	options: {
		variables: {
			chapterUrl: getCurrentPathname(),
		},
	},
});

export { bookQuery };
