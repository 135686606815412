const updateUrlWithPassageRange = (start, end, urn) => {
	if (window.history.replaceState && start && end && urn) {
		const origin = window.location.origin;

		window.history.replaceState(
			{ replacedAt: new Date() },
			'',
			`${origin}/read/${urn}:${start}-${end}`
		);
	}
};

export default updateUrlWithPassageRange;