/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import aboutThisTextQuery from '../../../graphql/queries/aboutThisText';

const SidePanelAbout = ({ urn }) => {
	const { loading, error, data } = useQuery(aboutThisTextQuery, {
		variables: { urn },
	});

	if (loading) {
		return <span>...</span>;
	}

	if (error) {
		return <span>Something went wrong, sorry. {error.message}</span>;
	}

	const work = data.works[0];

	if (!work)
		return (
			<div>
				<span>Sorry, I didn&apos;t find anything for that URN.</span>
			</div>
		);

	const aboutText = "<p>Add a description of your text in your account dashboard.</p>";

	return (
		<div className="sidePanelAboutText">
			<h3>About this text</h3>
			<div className="aboutText" dangerouslySetInnerHTML={{ __html: aboutText }} / >
		</div>
	);
};

SidePanelAbout.propTypes = {
	urn: PropTypes.string,
};

export default SidePanelAbout;
