import React from 'react';
import PropTypes from 'prop-types';
import Drawer from 'material-ui/Drawer';

import TableOfContents from '../TableOfContents';
import PublicationsContainer from '../../containers/PublicationsContainer';

class ReadingDrawer extends React.Component {
	static propTypes = {
		isOpen: PropTypes.bool.isRequired,
		handleToggle: PropTypes.func.isRequired,
		drawerPublications: PropTypes.bool.isRequired,
		book: PropTypes.object.isRequired,
		togglePublications: PropTypes.func.isRequired,
	}

	render = () => {
		const {
			isOpen, handleToggle, drawerPublications, book, tenant,
			togglePublications
		} = this.props;

		return (
			<Drawer
				open={isOpen}
				docked={false}
				width={360}
				onRequestChange={handleToggle.bind(this)}
			>
				{
					drawerPublications ?
						<div>
							<PublicationsContainer
								tenantId={tenant._id}
							/>
						</div>
					:
						<div>
							<div className="headerBlock">
								<div className="authorBlock">
									<span className="authorName">
										<i>{book.author}</i>
									</span>
								</div>
								<div className="titleBox">
									<h3 className="bookTitle">
										{book.title}
									</h3>
									<div
										className="showPublications"
										onClick={togglePublications.bind(this)}
									>

										<i className="showPublications mdi mdi-reply" />
									</div>
								</div>

								{ book.year && book.publisher ?
									<span className="bookPublicationInfo">
										{book.year}, {book.publisher}
									</span>
								: ''}
							</div>

							<TableOfContents
								book={book}
							/>
						</div>
				}
			</Drawer>
		);
	}
}

export default ReadingDrawer;
