/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import gql from 'graphql-tag';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { trim, truncate } from 'lodash/string';
import { useLazyQuery } from '@apollo/react-hooks';

const SEARCH_QUERY = gql`
	query textNodeSearchQuery(
		$after: Int
		$before: Int
		$first: Int
		$last: Int
		$textSearch: String
		$workId: Int
	) {
		results: textNodeSearch(
			after: $after
			before: $before
			first: $first
			last: $last
			textSearch: $textSearch
			workId: $workId
		) {
			pageInfo {
				count
				hasNextPage
				hasPreviousPage
				pages
			}
			textNodes {
				id
				location
				text
				urn
			}
		}
	}
`;

function SearchResultsNavigation({
	firstNodeId,
	lastNodeId,
	pageInfo: { hasNextPage, hasPreviousPage },
	searchFn,
}) {
	if (!hasNextPage && !hasPreviousPage) return null;

	const next = evt => {
		evt.preventDefault();

		searchFn({ after: lastNodeId });
	};

	const prev = evt => {
		evt.preventDefault();

		searchFn({ before: firstNodeId });
	};

	const justifyContent =
		hasPreviousPage && hasNextPage
			? 'space-between'
			: hasPreviousPage
			? 'flext-start'
			: 'flex-end';

	return (
		<div className="flex w100" style={{ justifyContent }}>
			{hasPreviousPage && (
				<span className="pointer" onClick={prev}>
					&lsaquo; Previous
				</span>
			)}
			{hasNextPage && (
				<span className="pointer" onClick={next}>
					Next &rsaquo;
				</span>
			)}
		</div>
	);
}

SearchResultsNavigation.propTypes = {
	firstNodeId: PropTypes.number,
	lastNodeId: PropTypes.number,
	pageInfo: PropTypes.shape({
		count: PropTypes.number,
		hasNextPage: PropTypes.bool,
		hasPreviousPage: PropTypes.bool,
		pages: PropTypes.number,
	}),
	searchFn: PropTypes.func,
};

const renderSearchResults = (
	urn,
	searchResults,
	searchPerformed = false,
	searchFn
) => {
	if (!searchPerformed) return null;

	const { pageInfo, textNodes } = searchResults;
	const numVisible = textNodes.length;

	if (searchPerformed && numVisible === 0) {
		return (
			<div className="searchResults">
				<ul className="list-style-none">
					<li>
						<span>Sorry, we didn&apos;t find anything for that query.</span>
					</li>
				</ul>
			</div>
		);
	}

	const searchResultLinks = textNodes.map((tn, i) => (
		<li key={`${tn.urn}-${i}`}>
			<Link to={`${urn}:${tn.location.join('.')}`}>
				<span className="location">{tn.location.join('.')}</span>
				{truncate(trim(tn.text.replace(/(<([^>]+)>)/gi, '')), {
					length: 40,
					separator: ' ',
				})}
			</Link>
		</li>
	));

	return (
		<div className="searchResults">
			<ul className="list-style-none">
				{searchResultLinks}
				<SearchResultsNavigation
					firstNodeId={textNodes[0].id}
					lastNodeId={textNodes[numVisible - 1].id}
					pageInfo={pageInfo}
					searchFn={searchFn}
				/>
			</ul>
		</div>
	);
};

export default function SearchMenu({ urn, workId }) {
	if (!urn) return null;

	const [searchPerformed, setSearchPerformed] = useState(false);
	const [searchResults, setSearchResults] = useState([]);
	const [searchString, setSearchString] = useState('');
	const [performSearch, { _error, _loading, fetchMore }] = useLazyQuery(
		SEARCH_QUERY,
		{
			onCompleted: resp => {
				setSearchPerformed(true);
				setSearchResults(resp.results);
			},
		}
	);

	useEffect(() => {
		const onKeyDown = e => {
			if (e.keyCode === 13 /* enter */) {
				e.preventDefault();
				e.stopPropagation();

				performSearch({
					variables: {
						urn,
						textSearch: searchString,
						workId,
					},
				});
			}
		}

		document.addEventListener('keydown', onKeyDown);

		return function cleanUp() {
			document.removeEventListener('keydown', onKeyDown);
		}
	}, [])

	const handleSearchStringChange = event => setSearchString(event.target.value);
	const handleSearchButtonClick = event => {
		event.preventDefault();
		event.stopPropagation();

		performSearch({
			variables: {
				urn,
				textSearch: searchString,
				workId,
			},
		});
	};
	const searchFn = ({ after, before }) => {
		fetchMore({
			query: SEARCH_QUERY,
			variables: {
				after,
				before,
				urn,
				textSearch: searchString,
				workId,
			},
			updateQuery: (__, { fetchMoreResult }) => {
				setSearchResults(fetchMoreResult.results);
			},
		});
	};

	const handleInputKeyPress = (ev) => {
    if (ev.key === 'Enter') {
			handleSearchButtonClick();
    }
	};

	return (
		<div className="px2 searchMenu readingEnvMenu">
			<h3>Search this work</h3>
			<form onSubmit={handleSearchButtonClick}>
				<TextField
					fullWidth
					label="Search term(s)"
					onChange={handleSearchStringChange}
					onKeyDown={handleInputKeyPress}
					size="small"
					value={searchString}
					variant="outlined"
				/>
				<button
					className="alexandriaButton"
				>
					<SearchIcon />
				</button>
			</form>
			{renderSearchResults(urn, searchResults, searchPerformed, searchFn)}
		</div>
	);
}

SearchMenu.propTypes = {
	urn: PropTypes.string,
	workId: PropTypes.number,
};
