import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import './Cover.css';


const Cover = ({
	classes, imageUrl, title, subtitle, label, lead, buttonText, buttonUrl,
	buttonExternal, center, mega, loading, isArchiveCover
}) => {
	const _classes = classes || ['cover chsCover'];

	if (center) {
		_classes.push('-center');
	}

	if (mega) {
		_classes.push('-mega');
	}

	if (loading) {
		_classes.push('-loading');
	}

	if (!imageUrl) {
		_classes.push('-no-cover-image');
	}

	if (isArchiveCover) {
		_classes.push('-is-archive-cover');
	}
	return (
		<div className={_classes.join(' ')}>
			{!mega &&
				<div
					className="coverBackground"
					style={{
						backgroundImage: `url(${imageUrl})`,
					}}
				/>
			}
			<div className="coverBackgroundGradient" />

			{!isArchiveCover && <div className="coverContent">
				{label &&
					<Typography variant="overline" component="label">
						{label}
					</Typography>
				}
				{title &&
					<h1>
						{title}
					</h1>
				}
				{subtitle &&
					<h2 className="subtitle">
						{subtitle}
					</h2>
				}
				{lead &&
					<p>
						{lead}
					</p>
				}
				{(buttonText && buttonUrl) &&
					<Button
						variant="contained"
						size="large"
						color="primary"
						href={buttonUrl}
						{...(buttonExternal ? {target: '_blank'} : {})}
					>
						{buttonText}
					</Button>
				}

				{mega &&
					<div className="coverMedia">
						<img src={imageUrl} alt="" />
					</div>
				}

			</div>}

		</div>
	);
};

Cover.propTypes = {
	classes: PropTypes.array,
	imageUrl: PropTypes.string,
	title: PropTypes.string,
	label: PropTypes.string,
	lead: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
	buttonText: PropTypes.string,
	buttonUrl: PropTypes.string,
	buttonExternal: PropTypes.bool,
	loading: PropTypes.bool,
}

export default Cover;
