/**
 * @prettier
 */

import gql from 'graphql-tag';

// $urn can include citations and subrefs
const query = gql`
	query textWorksQuery(
		$urn: String
		$endIndex: Int
		$startIndex: Int
		$offset: Int
	) {
		works(full_urn: $urn) {
			id
			textLocationNext(index: $endIndex, offset: $offset)
			textLocationPrev(index: $startIndex, offset: $offset)
		}
	}
`;

export default query;
