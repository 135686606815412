import React from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'react-timeago';
import ReactPlayer from 'react-player';
import IconDescription from '@material-ui/icons/Description';

import './Card.css';


const Card = (props) => {

	const {
		to,
		href,
		title,
		textShort,
		textLong,
		assetImageSrc,
		assetImageWidth,
		assetImageHeight,
		assetImageAlt,
		assetVideoSrc,
		assetAudioSrc,
		assetPdfSrc,
		assetMediaUrl,
		// asset3DSrc,
		metadata,
		footer,
		horizontal,
		loading,
		classes
	} = props;
	const _classes = classes || [];

	_classes.push('card');

	if (horizontal) {
		_classes.push('-horizontal');
	}

	if (loading) {
		_classes.push('-loading');
	}

	if (assetImageSrc) {
		_classes.push('-media');
	}

	const cardContent = (
		<React.Fragment>
			{title &&
				<div className="cardTitle">{title}</div>
			}
			{textShort &&
				<div className="cardTextShort">{textShort}</div>
			}
			{textLong &&
				<div className="cardTextLong">{textLong}</div>
			}
			{assetImageSrc &&
				<div className="cardMedia">
					<img src={assetImageSrc} alt={assetImageAlt} width={assetImageWidth} height={assetImageHeight} />
				</div>
			}
			{assetVideoSrc &&
				<div className="cardMedia cardMediaAV cardMediaVideo">
					<ReactPlayer
						url={assetVideoSrc}
						controls
						width="560px"
						height="320px"
					/>
				</div>
			}
			{assetAudioSrc &&
				<div className="cardMedia cardMediaAV cardMediaAudio">
					<ReactPlayer
						url={assetAudioSrc}
						controls
						width="560px"
						height="54px"
					/>
				</div>
			}
			{assetPdfSrc &&
				<div className="cardMedia cardMediaAV cardMediaPDF">
					<IconDescription />
					<span className="pdfLabel">PDF</span>
				</div>
			}
			{metadata &&
				<div className="cardMetas">
					{metadata.map(function(d, idx) {
						return (
							<div className="cardMeta" key={`${d.metaLabel}-${d.metaValue}-${idx}`}>
								<div className="cardMetaLabel">{d.metaLabel}</div>
								<div className="cardMetaValue">{d.metaValue}</div>
							</div>
						)
					})}
				</div>
			}
			{footer &&
				<div className="cardFooter">
					<div className="cardFooterMeta">
						<a className="cardFooterMetaUser" href={footer.authorURL}>
							<div className="cardFooterMetaUserImage">
								<img src={footer.authorImageURL} alt="" width={footer.authorImageWidth} height={footer.authorImageHeight} />
							</div>
							<span className="cardFooterMetaUserName">{footer.authorName}</span>
						</a>
						<div className="cardFooterMetaPosted"><TimeAgo date={footer.postedDate} /></div>
					</div>
					<div className="cardFooterActions">
					</div>
				</div>
			}
		</React.Fragment>
	);

	if (to && to.length) {
		return (
			<a className={_classes.join(' ')} href={to}>
				{cardContent}
			</a>
		);
	}

	return (
		<a
			className={_classes.join(' ')}
			href={href}
			target="_blank"
			rel="noopener noreferrer"
		>
			{cardContent}
		</a>
	);
};

Card.propTypes = {
	to: PropTypes.string,
	href: PropTypes.string,
	title: PropTypes.string,
	textShort: PropTypes.string,
	textLong: PropTypes.string,
	assetImageSrc: PropTypes.string,
	assetImageWidth: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	assetImageHeight: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	assetImageAlt: PropTypes.string,
	assetAudioSrc: PropTypes.string,
	assetVideoSrc: PropTypes.string,
	asset3DSrc: PropTypes.string,
	metadata: PropTypes.arrayOf(PropTypes.shape({
		metaLabel: PropTypes.string,
		metaValue: PropTypes.string,
	})),
	footer: PropTypes.shape({
		authorURL: PropTypes.string,
		authorImageURL: PropTypes.string,
		authorImageWidth: PropTypes.string,
		authorImageHeight: PropTypes.string,
		authorName: PropTypes.string,
		postedDate: PropTypes.instanceOf(Date),
	}),
	horizontal: PropTypes.bool,
	loading: PropTypes.bool,
	children: PropTypes.node,
};

export default Card;
