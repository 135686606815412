import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import RaisedButton from 'material-ui/RaisedButton';

import { recaptcha } from '../../../lib/config';
import RecaptchaFormsyInput from '../RecaptchaFormsyInput';
import OAuthButtons from '../OAuthButtons';

class SignUp extends React.Component {

	static propTypes = {
		openUserModal: PropTypes.func.isRequired,
		signUpMethod: PropTypes.func.isRequired,
		errors: PropTypes.array.isRequired,
	}

	constructor(props) {
		super(props);

		this.state = {
			canSubmit: false,
			data: null,
		};
	}

	_enableButton = () => {
		this.setState({
			canSubmit: true,
		});
	}

	_disableButton = () => {
		this.setState({
			canSubmit: false,
		});
	}

	_submitForm = (data) => {
		const { signUpMethod } = this.props;

		// hande form submit with valid data:
		// execute signUpMethod passed from props
		// pass data to method attr
		// sing in method: password
		signUpMethod(data, 'password');
	}

	renderErrors = () => {
		const { errors } = this.props;

		return (
			<div className="account-modal-errors">
				{errors.map((error, i) => (
					<span
						key={i}
						className="account-modal-errors--message"
					>
						{error.message}
					</span>
				))}
			</div>
		);
	}

	render = () => {

		const { openUserModal, signUpMethod } = this.props;

		const emailError = 'email error';
		const passwordError = 'password error';

		return (
			<div className="modalAuthForm signUp">
				<Formsy
					onValid={this._enableButton.bind(this)}
					onInvalid={this._disableButton.bind(this)}
					onValidSubmit={this._submitForm.bind(this)}
				>
					<h3 className="at-title modalAuthTitle">Create an Account</h3>
					{/*
					<OAuthButtons
						handleFacebook={signUpMethod.bind(null, null, 'facebook')}
						handleGoogle={signUpMethod.bind(null, null, 'google')}
						handleTwitter={signUpMethod.bind(null, null, 'twitter')}
					/>
					<div className="at-sep">
						<strong>OR</strong>
					</div>
					*/}
					{this.renderErrors()}
					<input
						name="email"
						placeholder="Email"
						validations="isEmail"
						validationError={emailError}
						required
						hintText="name@example.com"
						floatingLabelText="Email"
						fullWidth
					/>
					<input
						name="password"
						placeholder="Password"
						validationError={passwordError}
						required
						hintText="password"
						floatingLabelText="Password"
						type="password"
						fullWidth
					/>
					<input
						name="passwordRepeat"
						placeholder="Password (again)"
						validationError={passwordError}
						required
						hintText="repeat password"
						floatingLabelText="Repeat Password"
						type="password"
						fullWidth
					/>
					{/*
					<RecaptchaFormsyInput
						name="recaptcha"
						required
						recaptchaKey={recaptcha.KEY}
						style={{paddingTop: 20}}
					/>
					*/}
					<RaisedButton
						type="submit"
						label="Register"
						disabled={!this.state.canSubmit}
						className="submitButton"
						style={{
							height: '50px',
						}}
						primary
						fullWidth
					/>
					<div className="register">
						Go back to&nbsp;
						<a
							onTouchTap={openUserModal.bind(this, true)}
						>
							Sign In
						</a>
					</div>
				</Formsy>
			</div>
		);
	}
}

export default SignUp;
