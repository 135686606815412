import React from 'react';
import PropTypes from 'prop-types';

const AvatarIcon = (props) => {
	const defaultAvatar = '//chs.harvard.edu/CHS/assets/default_user.jpg';
	const avatar = props.avatar ? props.avatar : defaultAvatar;
	const altText = props.altText ? props.altText : '';
	const className = props.className ? props.className : '';

	return (
		<div
			className={`${className} avatar ${props.isAuthor ? 'avatarIsAuthor' : ''}`}
			style={{
				backgroundImage: `url('${avatar}')`,
				backgroundSize: 'cover',
			}}
			alt={altText}
		/>);
};

AvatarIcon.propTypes = {
	avatar: PropTypes.string,
	altText: PropTypes.string,
	className: PropTypes.string,
	isAuthor: PropTypes.bool,
};

export default AvatarIcon;
