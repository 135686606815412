/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

import CommentForm from '../CommentForm';
import SidePanelContent from './SidePanelContent';
import TranslationForm from '../TranslationForm';

const SidePanel = ({
	currentComment = {},
	currentTranslation = {},
	hideCommentForm,
	hideTranslationForm,
	openCommentForm,
	projectId,
	refetchComments,
	refetchCommentCounts,
	refetchTranslations,
	selectionText,
	selectionUrn,
	setCurrentComment,
	showCommentForm,
	showTranslationForm,
	sidePanelOpen,
	text,
	toggleSidePanel,
}) => {
	const _hideCommentForm = e => {
		setCurrentComment(null);
		hideCommentForm(e);
	};

	return (
		<div className={`sidePanelOuter ${sidePanelOpen ? '-sidePanelOpen' : ''}`}>
			<div className="sidePanelHandle" onClick={toggleSidePanel}>
				<div className="sidePanelHandleBar">
					<div className="sidePanelHandleBarGrip">
						{sidePanelOpen ?
							<i className="mdi mdi-chevron-right" />
						:
							<i className="mdi mdi-chevron-left" />
						}
					</div>
				</div>
			</div>
			{sidePanelOpen && (
				<div className="sidePanel px2 hide-scrollbars">
					{showCommentForm && (
						<div className="sidePanelForm">
							<CommentForm
								comment={currentComment || {}}
								commentTarget={selectionText}
								hideCommentForm={_hideCommentForm}
								key={(currentComment || {}).id || selectionText}
								projectId={projectId}
								refetchComments={refetchComments}
								toggleSidePanel={toggleSidePanel}
								urn={selectionUrn}
							/>
						</div>
					)}
					{showTranslationForm && (
						<div className="sidePanelForm">
							<TranslationForm
								hideTranslationForm={hideTranslationForm}
								key={(currentTranslation || {}).id || selectionText}
								refetchTranslations={refetchTranslations}
								textForTranslation={selectionText}
								toggleSidePanel={toggleSidePanel}
								translation={currentTranslation || {}}
								urn={selectionUrn}
							/>
						</div>
					)}
					{!showCommentForm && !showTranslationForm && (
						<SidePanelContent
							focusedUrn={selectionUrn}
							openCommentForm={openCommentForm}
							projectId={projectId}
							refetchComments={refetchComments}
							setCurrentComment={setCurrentComment}
							text={text}
						/>
					)}
				</div>
			)}
		</div>
	);
};

SidePanel.propTypes = {
	closeTranslationForm: PropTypes.func,
	comments: PropTypes.array,
	currentComment: PropTypes.shape({
		id: PropTypes.number,
		latestRevision: PropTypes.shape({
			text: PropTypes.string,
			title: PropTypes.string,
		}),
	}),
	currentTranslation: PropTypes.shape({ id: PropTypes.number }),
	hideCommentForm: PropTypes.func,
	openCommentForm: PropTypes.func,
	hideTranslationForm: PropTypes.func,
	highlightedComments: PropTypes.array,
	projectId: PropTypes.string,
	refetchComments: PropTypes.func,
	refetchCommentCounts: PropTypes.func,
	refetchTranslations: PropTypes.func,
	selectionText: PropTypes.string,
	selectionUrn: PropTypes.string,
	setComments: PropTypes.func,
	setCurrentComment: PropTypes.func,
	showCommentForm: PropTypes.bool,
	showTranslationForm: PropTypes.bool,
	sidePanelOpen: PropTypes.bool,
	text: PropTypes.object,
	textNodes: PropTypes.array,
	toggleSidePanel: PropTypes.func,
	urn: PropTypes.string,
	userTexts: PropTypes.array,
};

export default SidePanel;
