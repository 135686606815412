import React from 'react';
import { compose } from 'react-apollo';
import { tenantQuery } from '../graphql/queries/tenants';
import { annotationCreateMutation, annotationAddRevisionMutation } from '../graphql/mutations/annotations';
import { getAuthedUserQuery } from '../graphql/queries/users';
import AnnotationCreate from '../components/AnnotationCreate';

export default compose(
	tenantQuery,
	annotationCreateMutation,
	annotationAddRevisionMutation,
	getAuthedUserQuery,
)(AnnotationCreate);
