/**
 * @prettier
 */

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { Bookmark, FindInPage, Toc } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Menu } from '@material-ui/core';

import BookmarkMenu from './BookmarkMenu';
import SearchMenu from './SearchMenu';

import getCurrentLocationSpan from '../../../lib/getCurrentLocationSpan';

import tableOfContentsQuery from '../../../graphql/queries/tableOfContents';

const ITEM_HEIGHT = 48;

const withoutPassageCitation = (urn = '') =>
	urn
		.split(':')
		.slice(0, 4)
		.join(':');

const getAuthorAndTitleText = (author, title) => {
	if (!author && !title) return null;
	if (!title) return author;
	if (!author) return title;

	return `${author}, ${title}`;
};

const showFile = blob => {
	// It is necessary to create a new blob object with mime-type explicitly set
	// otherwise only Chrome works like it should
	var newBlob = new Blob([blob], { type: 'application/epub+zip' });

	// IE doesn't allow using a blob object directly as link href
	// instead it is necessary to use msSaveOrOpenBlob
	if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveOrOpenBlob(newBlob);
		return;
	}

	// For other browsers:
	// Create a link pointing to the ObjectURL containing the blob.
	const data = window.URL.createObjectURL(newBlob);
	var link = document.createElement('a');
	link.href = data;
	link.download = 'export.epub';
	link.click();
	setTimeout(function() {
		// For Firefox it is necessary to delay revoking the ObjectURL
		window.URL.revokeObjectURL(data);
	}, 100);
};

const handleDownloadEpub = () => {
	fetch('http://api.text-export.archimedes.digital/commentary', {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			urn: withoutPassageCitation(
				window.location.pathname.replace('/read/', '')
			),
		}),
	})
		.then(r => r.blob())
		.then(showFile);
};
const handleCopyCitation = () => {};
const handleFontSizeSmaller = () => {};
const handleFontSizeBigger = () => {};

const renderMenuItems = (works = [], textInfo, handleClick) => {
	if (!works || !works.length) {
		return (
			<li onClick={handleClick}>
				<span className="warning">
					Sorry, we couldn&apos;t find any information for this work.
				</span>
			</li>
		);
	}

	const work = works[0];
	const { refsDecls = [], tableOfContent: toc } = work;
	const { full_urn } = textInfo;

	let topLevelStartLocation = '';

	if (refsDecls.length > 0) {
		topLevelStartLocation = '.1'.repeat(refsDecls.length - 1);
	} else {
		topLevelStartLocation = '.1';
	}

	const nextLevelStartLocation =
		refsDecls.length > 2 ? '.1'.repeat(refsDecls.length - 2) : '';

	return toc.map((item, i) => (
		<li className="initial-caps" key={`toc-${i}`} onClick={handleClick}>
			<Link to={`${full_urn}:${item.index}${topLevelStartLocation}`}>
				{item.label} {item.index}{topLevelStartLocation}
			</Link>
			{refsDecls.length > 2 && item.children && item.children.length && (
				<ul className="list-style-none">
					{item.children.map((c, j) => (
						<li className="lh2" key={`toc-sub-${j}`} onClick={handleClick}>
							<Link
								to={`${full_urn}:${item.index}.${c.index}${nextLevelStartLocation}`}
							>
								{c.label} {item.index}.{c.index}{nextLevelStartLocation}
							</Link>
						</li>
					))}
				</ul>
			)}
		</li>
	));
};

const HeaderMenu = ({ anchorEl, children, id, onClose }) => {
	return (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{
				horizontal: 'center',
				vertical: 'bottom',
			}}
			getContentAnchorEl={null}
			id={id}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={onClose}
			transformOrigin={{
				horizontal: 'right',
				vertical: 'bottom',
			}}
			PaperProps={{
				className: 'hide-scrollbars',
				style: {
					maxHeight: ITEM_HEIGHT * 9,
					width: 500,
				},
			}}
		>
			{children}
		</Menu>
	);
};

HeaderMenu.propTypes = {
	anchorEl: PropTypes.any,
	children: PropTypes.node,
	id: PropTypes.string,
	onClose: PropTypes.func.isRequired,
};


// headroom
const renderHeadroom = () => {
	var headerEl = document.querySelector('header');
	if (headerEl) {
		var headroom = new Headroom(headerEl);
		headroom.init();
		// initial page state
	  if (window.pageYOffset > 0) {
	    headerEl.classList.add('headroom--not-top');
	  }
	}
};


const Header = ({ text = {}, textNodes }) => {
	const { textGroup = {}, work = {} } = text;
	const [start, end] = getCurrentLocationSpan(textNodes);
	const [bookmarkMenuAnchorEl, setBookmarkMenuAnchorEl] = useState(null);
	const [searchMenuAnchorEl, setSearchMenuAnchorEl] = useState(null);
	const [tocMenuAnchorEl, setTocMenuAnchorEl] = useState(null);
	const { loading, error, data } = useQuery(tableOfContentsQuery, {
		variables: { fullUrn: work.full_urn },
	});
	const workTitle = work.original_title || work.english_title;

	const project = JSON.parse(document.getElementById('project').textContent);

	useEffect(() => {
		document.title = `${getAuthorAndTitleText(textGroup.title, workTitle) ||
			'New Alexandria'} ${start && end ? start + '-' + end : ''} ${
			project ? '| ' + project.title : ''
		}`;
	}, [
		(textNodes || []).map(tn => tn.location.join('.')).join('.'),
		textGroup.title,
		work.title,
	]);
	const handleBookmarkMenuClick = useCallback(event => {
		if (window.USER_IS_LOGGED_IN) {
			setBookmarkMenuAnchorEl(event.currentTarget);
		} else {
			window.location = `/login/?redirect_to=${window.location}`;
		}
	});
	const handleBookmarkMenuClose = useCallback(_event =>
		setBookmarkMenuAnchorEl(null)
	);
	const handleSearchMenuClick = useCallback(event =>
		setSearchMenuAnchorEl(event.currentTarget)
	);
	const handleSearchMenuClose = useCallback(_event =>
		setSearchMenuAnchorEl(null)
	);
	const handleTocMenuClick = useCallback(event =>
		setTocMenuAnchorEl(event.currentTarget)
	);
	const handleTocMenuClose = useCallback(_event => setTocMenuAnchorEl(null));

	return (
		<header className="headerExternal" >
			{renderHeadroom()}
			<nav className="flex space-between w-100">
				<ul className="items-start">
					<li>
						<a href="/" className="headerTitle">
							{project && project.title}
						</a>
					</li>
				</ul>
				{textGroup.title && (
					<>
						<ul className="headerLocation">
							<li>
								<a
									className="workTitle"
									href={`/texts/?page=1&Author=${textGroup.title}`}
								>
									{textGroup.title}
								</a>
								{workTitle && (
									<span className="workTitleText">
										, <em style={{ fontStyle: 'italic' }}>{workTitle}</em>{' '}
									</span>
								)}
								{(textNodes || []).length > 0 ? (
									<span> {`${start} – ${end}`}</span>
								) : null}
							</li>
						</ul>
						<ul className="items-end">
							<li>
								<button
									aria-controls="simple-menu"
									aria-haspopup="true"
									onClick={handleTocMenuClick}
								>
									<Toc />
								</button>
								<HeaderMenu
									anchorEl={tocMenuAnchorEl}
									id="toc-menu"
									onClose={handleTocMenuClose}
								>
									<div className="tocMenuUpper">
										<div className="tocMenuRow">
											<label>Download</label>
											<button onClick={handleDownloadEpub}>EPUB</button>
										</div>
										{/*
										<div className="tocMenuRow">
											<label>Cite this work</label>
											<button onClick={handleCopyCitation}>COPY</button>
										</div>
										<div className="tocMenuRow">
											<label>Font size</label>
											<div className="fontSizeControl">
												<button
													className="fontSizeButton"
													onClick={handleFontSizeSmaller}
												>
													<span className="littleA">A</span>
												</button>
												<button
													className="fontSizeButton"
													onClick={handleFontSizeBigger}
												>
													<span className="bigA">A</span>
												</button>
											</div>
										</div>
										*/}
									</div>
									{loading && (
										<span className="p4 tocMenuLoading">Loading ...</span>
									)}
									{error && (
										<span className="error p4">
											Unable to load menu. {error.message}
										</span>
									)}
									{data && (
										<div>
											<h6 className="gray-dark p1 tocMenuHeading">
												Table of contents
											</h6>
											<ul className="list-style-none tocMenu">
												{renderMenuItems(data.works, work, handleTocMenuClose)}
											</ul>
										</div>
									)}
								</HeaderMenu>
							</li>
							<li>
								<button
									role="button"
									className="pointer"
									onClick={handleSearchMenuClick}
								>
									<FindInPage />
								</button>
								<HeaderMenu
									anchorEl={searchMenuAnchorEl}
									id="search-menu"
									onClose={handleSearchMenuClose}
								>
									<div>
										<SearchMenu urn={work.full_urn} workId={work.id} />
									</div>
								</HeaderMenu>
							</li>
							<li>
								<button
									role="button"
									className="pointer"
									onClick={handleBookmarkMenuClick}
								>
									<Bookmark />
								</button>
								<HeaderMenu
									anchorEl={bookmarkMenuAnchorEl}
									id="bookmark-menu"
									onClose={handleBookmarkMenuClose}
								>
									{/* This extra div captures the ref to BookmarkMenu */}
									<div>
										<BookmarkMenu urn={`${work.full_urn}:${start}-${end}`} />
									</div>
								</HeaderMenu>
							</li>
						</ul>
					</>
				)}
			</nav>
		</header>
	);
};

Header.propTypes = {
	text: PropTypes.shape({
		id: PropTypes.string,
		textGroup: PropTypes.shape({
			// textGroup.title is actually the "author" name
			title: PropTypes.string,
		}),
		title: PropTypes.string,
		work: PropTypes.shape({
			full_urn: PropTypes.string,
		}),
	}),
	textNodes: PropTypes.array,
};

export default Header;
