/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import IconClose from '@material-ui/icons/Close';
import autoBind from 'react-autobind';

// redux
import editorActions from '../../../actions';

import ItemEmbedContainer from '../../../../items/containers/ItemEmbedContainer';
import removeBlock from '../../../lib/removeBlock';

import './ItemBlock.css';

class ItemBlock extends React.Component {
  static propTypes = {
    block: PropTypes.shape({
      getData: PropTypes.func.isRequired,
    }).isRequired,
    contentState: PropTypes.object,
    editorState: PropTypes.object,
    setEditorState: PropTypes.func,
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  removeBlock() {
    const { block, editorState, setEditorState } = this.props;
    setEditorState(removeBlock(editorState, block.getKey()));
  }

  render() {
    const { block, contentState } = this.props;
    let { hostname, itemId } = block.getData().toJS();

    if (!itemId) {
      const entityKey = block.getEntityAt(0);

      if (!entityKey) return null;

      const entity = contentState.getEntity(entityKey);

      const data = entity.getData();

      hostname = data.hostname;
      itemId = data.itemId;
    }

    return (
      <figure className="itemBlock">
        <div className="embeddedContent">
          <ItemEmbedContainer hostname={hostname} itemId={itemId} />
          {this.renderRemoveButton()}
        </div>
      </figure>
    );
  }

  renderRemoveButton() {
    if (
      this.props.userId &&
      (location.pathname.endsWith('/edit/') ||
        location.pathname.endsWith('/create/'))
    ) {
      return (
        <div className="itemBlockRemove">
          <IconButton onClick={this.removeBlock}>
            <IconClose />
          </IconButton>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  editorState: state.editor.editorState,
  userId: state.auth.userId,
});

const mapDispatchToProps = dispatch => ({
  setEditorState: editorState => {
    dispatch(editorActions.setEditorState(editorState));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemBlock);
