import React from 'react';
import PropTypes from 'prop-types';
import Recaptcha from 'react-grecaptcha';
import stylePropType from 'react-style-proptype';

class RecaptchaFormsyInput extends React.Component {

	static propTypes = {
		// props recieved from formsy HOC:
		setValue: PropTypes.func.isRequired,
		// getValue: PropTypes.func.isRequired,
		getErrorMessage: PropTypes.func.isRequired,
		// showRequired: PropTypes.func.isRequired,
		// showError: PropTypes.func.isRequired,

		// props from parent element:
		recaptchaKey: PropTypes.string.isRequired,
		style: stylePropType,
	};

	static defaultProps = {
		style: {},
	};

	// setValue() will set the value of the component, which in
	// turn will validate it and the rest of the form
	_recaptchaCallback = (request) => {
		this.props.setValue(request);
	}

	_expiredCallback = () => {
		this.props.setValue(null);
	}

	render = () => {
		const { getErrorMessage, recaptchaKey, style } = this.props;

		// const className = showRequired() ? 'required' : showError() ? 'error' : null;

		const errorMessage = getErrorMessage();

		return (
			<div style={style}>
				<Recaptcha
					sitekey={recaptchaKey}
					style={{paddingTop: 20}}
					callback={this._recaptchaCallback.bind(this)}
					expiredCallback={this._expiredCallback.bind(this)}
				/>
				<span>{errorMessage}</span>
			</div>
		);
	}
}

export default RecaptchaFormsyInput;
