import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import IconButton from 'material-ui/IconButton';
import autobind from 'react-autobind';
import Cookies from 'js-cookie';

import ddpclient from '../../../middleware/ddp';
import Utils from '../../../lib/utils';
import SignIn from '../SignIn';
import SignUp from '../SignUp';


class UserModal extends React.Component {

	static propTypes = {
		visible: PropTypes.bool.isRequired,
		signInVisible: PropTypes.bool.isRequired,
		openUserModal: PropTypes.func.isRequired,
		closeUserModal: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props);

		this.state = {
			errors: [],
		};

		autobind(this);
	}

	_signInMethod = (data, method) => {

		switch (method) {
		case 'password':
			this._signInPassword(data);
			break;
		case 'facebook':
			// TODO handle singIn
			this._signInFacebook(data);
			break;
		case 'google':
			// TODO handle singIn
			break;
		case 'twitter':
			// TODO handle singIn
			break;
		default:
			console.error('wrong method provided to _signInMethod method');
		}
	}

	_signUpMethod = (data, method) => { // eslint-disable-line
		// handle sign up

		switch (method) {
		case 'password':
			this._signUpPassword(data);
			break;
		case 'facebook':
			// TODO handle singUp
			break;
		case 'google':
			// TODO handle singUp
			break;
		case 'twitter':
			// TODO handle singUp
			break;
		default:
			console.error('wrong method provided to _signUpMethod method');
		}
	}

	_signInPassword = (data) => {
		const self = this;

		ddpclient.call('login',
			[{user: { email: data.email}, password: data.password }],
			(error, userInfo) => {
				const domain = Utils.getEnvDomain();

				if (error) {
					console.error(`Error logging in ${error.message}`);
					self.setState({
						errors: [error],
					});
				} else {
					if (domain) {
						Cookies.set('userId', userInfo.id, { domain, });
						Cookies.set('loginToken', userInfo.token, { domain });
					} else {
						Cookies.set('userId', userInfo.id);
						Cookies.set('loginToken', userInfo.token);
					}

					// close user panel
					this.props.closeUserModal();
					setTimeout(() => {
						window.location.reload();
					}, 100);
				}
			});
	}

	_signUpPassword = (data) => {
		const self = this;

		if (data.password !== data.passwordRepeat) {
			this.setState({
				errors: [{
					message: 'Passwords do not match.',
				}],
			});
			throw new Meteor.Error('Passwords do not match');
		}

		ddpclient.call('createAccountHTTPS',
			[{ email: data.email, password: data.password }],
			(error, userInfo) => {
				const domain = Utils.getEnvDomain();

				if (error) {
					console.error(`Error signing up ${error.message}`);
					self.setState({
						errors: [error],
					});
				} else {
					if (domain) {
						Cookies.set('userId', userInfo.userId, { domain, });
						Cookies.set('loginToken', userInfo.stampedToken, { domain });
					} else {
						Cookies.set('userId', userInfo.userId);
						Cookies.set('loginToken', userInfo.stampedToken);
					}

					// close user panel
					this.props.closeUserModal();
					setTimeout(() => {
						window.location.reload();
					}, 100);
				}
			});
	}

	_signInFacebook = (data) => {
		const self = this;

		ddpclient.call('loginWithFacebook',
			[],
			(error, userInfo) => {
				const path = '/';
				const domain = Utils.getEnvDomain();

				if (error) {
					console.error(`Error logging in ${error.message}`);
					self.setState({
						errors: [error],
					});
				} else {
					if (domain) {
						Cookies.set('userId', userInfo.id, { domain });
						Cookies.set('loginToken', userInfo.token, { domain });
					} else {
						Cookies.set('userId', userInfo.id);
						Cookies.set('loginToken', userInfo.token);
					}

					// close user panel
					this.props.closeUserModal();
					setTimeout(() => {
						window.location.reload();
					}, 100);
				}
			});
	}

	render = () => {

		const { visible, closeUserModal, signInVisible } = this.props;

		const modalStyle = {
			overlay: {
				zIndex: 9999, // position above all other elements
				background: 'rgba(255, 255, 255, 0.95)',
				width: '100%',
				height: '100%',
				right: 'auto',
				bottom: 'auto',
				overflowY: 'scroll',
			},
			content: {
				position: 'relative',
				top: 'auto',
				left: 'auto',
				right: 'auto',
				bottom: 'auto',
				background: 'transparent',
				border: '1px solid transparent',
				outline: 'none',
				width: '90%',
				maxWidth: '600px',
				minHeight: '700px',
				textAlign: 'center',
				margin: '0 auto',
				transform: 'none',
			},
		};


		return (
			<ReactModal
				isOpen={visible}
				contentLabel="Login and Sign up"
				style={modalStyle}
				onRequestClose={closeUserModal}
				shouldCloseOnOverlayClick
			>
				<div
					className="closeModalButton paperShadow"
					onClick={closeUserModal}
				>
					<i className="mdi mdi-close" />
				</div>
				{signInVisible ?
					<SignIn
						openUserModal={this.props.openUserModal}
						signInMethod={this._signInMethod.bind(this)}
						errors={this.state.errors}
					/>
				:
					<SignUp
						openUserModal={this.props.openUserModal}
						signUpMethod={this._signUpMethod.bind(this)}
						errors={this.state.errors}
					/>
				}
			</ReactModal>
		);
	}
}

export default UserModal;
