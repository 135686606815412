import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import App from './components/App';
import EpubReader from './components/EpubReader';
import client from '../annotations/middleware/apolloClient';
import configureStore from '../annotations/store/configureStore';


const store = configureStore();

const ReadingEnvironment = () => (
	<ApolloProvider
		client={client}
		store={store}
	>
		<MuiThemeProvider>
			{/*}
      <App urn="urn:cts:chs:Nagy.AncientGreekHero:part-i-introduction-to-homeric-poetry" />
			*/}
			<EpubReader />
		</MuiThemeProvider>
	</ApolloProvider>
);

export default ReadingEnvironment;
