import React from 'react';
import { compose } from 'react-apollo';
import { getAuthedUserQuery, usersGetPublicById } from '../graphql/queries/users';
import { annotationRemoveMutation } from '../graphql/mutations/annotations';
import Annotation from '../components/Annotation';

export default compose(
	usersGetPublicById,
	getAuthedUserQuery,
	annotationRemoveMutation,
)(Annotation);
