/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import DraftOffsetKey from 'draft-js/lib/DraftOffsetKey';
import autoBind from 'react-autobind';
import cn from 'classnames';
import { DraftEditor, EditorState } from 'draft-js';

// components
import AddTooltipMenu from '../AddTooltipMenu';
import AddTooltipButton from '../AddTooltipButton';

import getCurrentBlock, {
  getEndBlock,
  getStartBlock,
} from '../../../../lib/getCurrentBlock';
import { LIST_ITEM_BLOCK_TYPES } from '../../../../lib/draftJsBlockTypes';

// icons
import { MdAdd } from 'react-icons/md';

import './AddTooltip.css';

const OFFSET = -13;

const clickedOnAddTooltip = evt => {
  let node = evt.target.parentNode;
  let className = null;

  while (!className && node) {
    className = node.className;
    node = node.parentNode;
  }

  // NOTE: (charles) These are possible className targets for
  // the user to click, after which clicks we don't want to
  // to hide the tooltip. There is probably a better way to
  // handle these clicks, but because the DOM contents of
  // the tooltip change, it was difficult to find a target
  // node for reliably canceling the hiding function
  return ['addTooltipMenu', 'addTooltipMenuItemButton'].some(
    s => String(className).indexOf(s) > -1
  );
};

class AddTooltip extends React.Component {
  static propTypes = {
    editorRef: PropTypes.object.isRequired,
    editorState: PropTypes.instanceOf(EditorState).isRequired,
  };

  constructor(props) {
    super(props);

    autoBind(this);

    this.state = {
      menuVisible: false,
      position: {
        top: 0,
      },
      visible: false,
    };
  }

  componentDidMount() {
    window.addEventListener('click', this.collapseAddTooltipMenu);
  }

  componentDidUpdate(prevProps, prevState) {
    const { editorState } = this.props;
    const selection = editorState.getSelection();

    if (!selection.getHasFocus()) {
      if (prevState.visible) this._hideTooltip();
      return;
    }

    this._handleEditorStateChange(prevProps, prevState);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.collapseAddTooltipMenu);
  }

  // TODO: (charles) Also hide on `ESC` keypress.
  collapseAddTooltipMenu(evt) {
    if (clickedOnAddTooltip(evt)) return;

    const { menuVisible } = this.state;

    if (menuVisible) {
      evt.preventDefault();
      evt.stopPropagation();

      this.setState(
        {
          menuVisible: false,
        },
        () => this.props.editorRef.current.focus()
      );
    }
  }

  onMouseDown(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  toggleAddTooltipMenu(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.setState({
      menuVisible: !this.state.menuVisible,
    });
  }

  render() {
    const { menuVisible, position, visible } = this.state;
    const className = cn('addTooltip', {
      addTooltipVisible: visible && !this._isInListMode(),
      addTooltipMenuVisible: menuVisible,
    });

    return (
      <div
        className={className}
        onMouseDown={this.onMouseDown}
        style={position}
      >
        <AddTooltipButton
          onClick={this.toggleAddTooltipMenu}
          type="button"
          className="addTooltipToggle"
        >
          <MdAdd />
        </AddTooltipButton>
        <AddTooltipMenu
          closeMenu={this.toggleAddTooltipMenu}
          visible={menuVisible}
        />
      </div>
    );
  }

  // eslint-disable-next-line no-unused-vars
  _handleEditorStateChange(prevProps, prevState) {
    const { editorRef, editorState } = this.props;

    if (!editorRef) return;

    const endBlock = getEndBlock(editorState);

    if (!endBlock) return;

    const startBlock = getStartBlock(editorState);

    if (
      endBlock &&
      (endBlock.getText() ||
        !endBlock.equals(startBlock) ||
        !editorState.getSelection().getHasFocus())
    ) {
      if (this.state.visible) this._hideTooltip();
      return;
    }

    const offsetKey = DraftOffsetKey.encode(endBlock.getKey(), 0, 0);

    // we need to wait a tick for the DOM to update
    this.timeout = setTimeout(() => {
      const node = document.querySelectorAll(
        `[data-offset-key="${offsetKey}"]`
      )[0];

      if (!node) return;

      const editorRoot = editorRef.current.editorContainer.parentNode;

      this.setState({
        position: {
          top: node.offsetTop + editorRoot.offsetTop + OFFSET,
        },
        visible: true,
      });
    }, 0);
  }

  _hideTooltip() {
    clearTimeout(this.timeout);
    this.setState({
      visible: false,
    });
  }

  _isInListMode() {
    return (
      LIST_ITEM_BLOCK_TYPES.indexOf(
        getCurrentBlock(this.props.editorState).getType()
      ) > -1
    );
  }
}

export default AddTooltip;
