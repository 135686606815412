/**
 * @prettier
 */

import {
	ContentState,
	EditorState,
	convertFromHTML,
	convertFromRaw,
} from 'draft-js';

export const createBaseEditorState = (revision, decorators = null) => {
	if (revision.text) {
		let baseContent = null;

		try {
			baseContent = convertFromRaw(JSON.parse(revision.text));
		} catch (e) {
			console.error(e);
			const { contentBlocks, entityMap } = convertFromHTML(
				`<span>${revision.text}</span>`
			);
			baseContent = ContentState.createFromBlockArray(contentBlocks, entityMap);
		}

		const baseEditorState = EditorState.createWithContent(
			baseContent,
			decorators
		);

		return baseEditorState;
	}

	return EditorState.createEmpty(decorators);
};

export default createBaseEditorState;
