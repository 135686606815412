import React from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';

// redux
import editorActions from '../../../../../actions';

// components
import AddTooltipMenuItemButton from '../../AddTooltipMenuItemButton';

// icons
import { MdInsertDriveFile } from "react-icons/md";



class AddItemButton extends React.Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	handleAddItem() {
		const {
			addTooltip,
			setAddTooltip,
			setEditorState,
			editorState,
		} = this.props;


		let itemId = '';

		const newEditorState = insertNewBlock(editorState, 'atomic', { type: 'ITEM', itemId, });
		setEditorState(newEditorState);
		setAddTooltip({
			...addTooltip,
			visible: false,
			menuVisible: false,
		});
	}

	render() {
		return (
			<AddTooltipMenuItemButton
				onClick={this.handleAddItem}
			>
				<MdInsertDriveFile />
				<span>Item</span>
			</AddTooltipMenuItemButton>
		);
	}
}

const mapStateToProps = state => ({
	...state.editor,
});

const mapDispatchToProps = dispatch => ({
	setEditorState: (editorState) => {
		dispatch(editorActions.setEditorState(editorState));
	},
	setAddTooltip: (addTooltip) => {
		dispatch(editorActions.setAddTooltip(addTooltip));
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AddItemButton);
