/**
 * @prettier
 */

import gql from 'graphql-tag';

const query = gql`
	query aboutThisTextQuery($urn: CtsUrn) {
		works(urn: $urn) {
			id
			english_title
			original_title
			structure
			form
			description
			language {
				id
				title
			}
			refsDecls {
				description
				label
				match_pattern
				replacement_pattern
				slug
			}
		}
	}
`;

export default query;
