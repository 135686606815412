/**
 * @prettier
 */

import React, { createRef, useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import EditIcon from '@material-ui/icons/Edit';
import ModeCommentTwoToneIcon from '@material-ui/icons/ModeCommentTwoTone';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import TranslateIcon from '@material-ui/icons/Translate';
import cn from 'classnames';
import {
	ContentState,
	EditorState,
	convertFromHTML,
	convertFromRaw,
	convertToRaw,
} from 'draft-js';

import CHSEditor from '../../../orpheus-editor/components/Editor';
import decorators from '../../../orpheus-editor/components/decorators';

import deriveLocationFromUrn from '../../lib/deriveLocationFromUrn';
import makeRequest from '../../lib/makeRequest';

const noop = () => {};

const withoutPassageCitation = (urn = '') =>
	urn
		.split(':')
		.slice(0, 4)
		.join(':');

const _createUserText = async userText => {
	return await makeRequest('usertexts/', {
		body: JSON.stringify(userText),
		method: 'POST',
	});
};

const _updateUserText = async userText => {
	return await makeRequest(`usertexts/${userText.id}/`, {
		body: JSON.stringify(userText),
		method: 'PUT',
	});
};

const renderCommentsIcon = (commentCount = 0, onClick = () => {}) => {
	const classes = cn(
		'f2 flex flex-column items-center pointer relative unselectable',
		{
			hidden: commentCount === 0,
		}
	);
	return (
		<div className={classes} onClick={onClick}>
			<div
				className="absolute left unselectable z2 commentIconCount"
				style={{
					fontWeight: 'bold',
					fontSize: 12,
					left: '50%',
					paddingBottom: 4,
					top: '50%',
					transform: 'translate(-50%, -50%)',
					fontFamily:
						'-apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Liberation Sans, Noto, sans-serif',
				}}
			>
				{commentCount}
			</div>
			<ModeCommentTwoToneIcon fontSize="inherit" />
		</div>
	);
};

const renderEditingIcon = (
	editing,
	classes,
	handleEditClick,
	handleCancelClick,
	handleSubmitClick
) => {
	if (editing) {
		return (
			<>
				<div className={cn(classes, 'mr1')} onClick={handleSubmitClick}>
					<CheckIcon />
				</div>
				<div className={cn(classes, 'mr1')} onClick={handleCancelClick}>
					<CloseIcon />
				</div>
			</>
		);
	}

	return (
		<div className={cn(classes, 'mr1')} onClick={handleEditClick}>
			<EditIcon />
		</div>
	);
};

const UserEditionTextNode = ({
	editing,
	editorState,
	handleCancel,
	handleSubmit,
	setEditorState,
}) => {
	const editorNode = createRef();

	useEffect(() => {
		if (editing) {
			try {
				editorNode.current.focus();
			} catch (e) {
				console.error('Failed to focus CHSEditor. Reason:', e.toString());
			}
		}
	}, [editing]);

	const classes = cn({
		editing: editing,
		p1: editing,
	});

	return (
		<div className={classes}>
			<CHSEditor
				editorState={editorState}
				handleChange={setEditorState}
				readOnly={!editing}
				ref={editorNode}
			/>
		</div>
	);
};

UserEditionTextNode.propTypes = {
	editing: PropTypes.bool,
	editorState: PropTypes.object,
	handleCancel: PropTypes.func,
	handleSubmit: PropTypes.func,
	userText: PropTypes.shape({
		revisions: PropTypes.array,
	}),
	setEditorState: PropTypes.func,
	textNode: PropTypes.shape({
		text: PropTypes.string,
	}),
};

function getBaseEditorState(textNode, userText) {
	let baseContent;
	if (Boolean(userText.content_type === 'EDITION')) {
		const latestRevision = userText.revisions[0];
		if (!latestRevision) return null;

		try {
			baseContent = convertFromRaw(JSON.parse(latestRevision.text));
		} catch (e) {
			const { contentBlocks, entityMap } = convertFromHTML(
				`<span>${latestRevision.text}</span>`
			);
			baseContent = ContentState.createFromBlockArray(contentBlocks, entityMap);
		}
	} else {
		const { contentBlocks, entityMap } = convertFromHTML(
			`<span>${textNode.text}</span>`
		);
		baseContent = ContentState.createFromBlockArray(contentBlocks, entityMap);
	}
	const baseEditorState = EditorState.createWithContent(
		baseContent,
		decorators
	);

	return baseEditorState;
}

const TextNode = ({
	commentCount,
	hideInteractiveButtons,
	openSidePanelForTranslation,
	openSidePanelForUrn,
	textNode,
	urn,
	userText = {},
}) => {
	const location = textNode.location || deriveLocationFromUrn(urn);
	urn = `${withoutPassageCitation(urn)}:${location.join('.')}`;
	const [localUserText, setLocalUserText] = useState(userText);
	const [documentHasSelection, setDocumentHasSelection] = useState(false);
	const [editing, setEditing] = useState(false);
	const [editorState, setEditorState] = useState(
		getBaseEditorState(textNode, userText)
	);
	// editor state to revert to on cancel
	const [baseEditorState, setBaseEditorState] = useState(editorState);

	if (editorState === null) {
		return null;
	}

	useLayoutEffect(() => {
		setDocumentHasSelection(!window.getSelection().isCollapsed);
	}, [window.getSelection().isCollapsed]);

	const createUserText = rawContent => {
		const revision = {
			text: rawContent,
		};

		const newUserText = {
			// Django appears to use caps by convention
			// see https://docs.djangoproject.com/en/3.0/ref/models/fields/#enumeration-types
			content_type: 'EDITION',
			revisions: [revision],
			urn,
		};

		return _createUserText(newUserText);
	};

	const updateUserText = rawContent => {
		const currentEdition = Object.assign({}, localUserText, {
			content_type: 'EDITION',
		});
		const now = new Date();
		const revision = {
			created_at: now,
			text: rawContent,
			updated_at: now,
		};

		currentEdition.revisions = currentEdition.revisions || [];
		currentEdition.revisions.push(revision);

		return _updateUserText(currentEdition);
	};

	const handleCommentClick = e => {
		e.preventDefault();

		openSidePanelForUrn(urn, editorState.getCurrentContent().getPlainText());
	};

	const handleCancelEdit = e => {
		e.preventDefault();

		setEditing(false);
		setEditorState(baseEditorState);
	};

	const handleEditClick = e => {
		e.preventDefault();

		setEditing(true);
		setBaseEditorState(editorState);
	};

	const handleSubmitEdit = async e => {
		e.preventDefault();

		let content;
		try {
			content = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
		} catch (e) {
			console.error('Could not convert content to raw. Reason:', e);
			return;
		}
		const result = Boolean((localUserText || {}).id)
			? await updateUserText(content)
			: await createUserText(content);

		if (Boolean(result)) {
			setEditing(false);
			setLocalUserText(result);
		}
	};

	const handleTranslateClick = e => {
		e.preventDefault();
		const textForTranslation = editorState.getCurrentContent().getPlainText();
		openSidePanelForTranslation(urn, textForTranslation);
	};

	const showCommentsForNode = _e => {
		openSidePanelForUrn(urn);
	};

	const inlineButtonClasses = cn(
		'child ease-in-out fw7 gray-dark pointer unselectable mr1',
		{
			hidden: documentHasSelection,
		}
	);
	const commentButtonClasses = cn(
		'ease-in-out fw7 pointer unselectable commentButton',
		{
			hidden: documentHasSelection,
		}
	);

	const textClasses = cn('textNode w-80', {
		indent: textNode.text.indexOf('<milestone') > -1,
	});

	// render empty line for empty text
	if (textNode.text === ""){
		textNode.text = "[...]";
	}

	return (
		<div
			className="flex hide-child"
			style={{ fontSize: '1.25rem', lineHeight: '1.6em' }}
		>
			<div className="flex flex-row items-center textNodeLocation">
				<div
					className="child ease-in-out fw7 gray-dark left pointer unselectable"
					onClick={hideInteractiveButtons ? noop : handleCommentClick}
					role="button"
					style={{ maxWidth: 70, width: 70 }}
				>
					{location.join('.')}
				</div>
			</div>
			<div
				className={textClasses}
				data-urn={urn}
				data-location={JSON.stringify(location)}
				style={{ flexGrow: 1 }}
			>
				<UserEditionTextNode
					editing={editing}
					editorState={editorState}
					handleCancel={handleCancelEdit}
					handleSubmit={handleSubmitEdit}
					setEditorState={setEditorState}
				/>
			</div>
			<div
				className="flex flex-row items-center px1 relative"
				style={{ top: 4 }}
			>
				{!hideInteractiveButtons &&
					renderEditingIcon(
						editing,
						inlineButtonClasses,
						handleEditClick,
						handleCancelEdit,
						handleSubmitEdit
					)}
				{!hideInteractiveButtons && (
					<div
						className={inlineButtonClasses}
						onClick={handleTranslateClick}
						role="button"
					>
						<TranslateIcon />
					</div>
				)}
				<div className={commentButtonClasses}>
					{renderCommentsIcon(commentCount, showCommentsForNode)}
				</div>
			</div>
		</div>
	);
};

TextNode.propTypes = {
	commentCount: PropTypes.number,
	hideInteractiveButtons: PropTypes.bool,
	openSidePanelForTranslation: PropTypes.func,
	openSidePanelForUrn: PropTypes.func,
	textNode: PropTypes.shape({
		location: PropTypes.array,
		text: PropTypes.string,
		urn: PropTypes.string,
	}).isRequired,
	urn: PropTypes.string,
	userText: PropTypes.shape({
		latest_revision_text: PropTypes.shape({
			text: PropTypes.string,
		}),
	}),
};

export default TextNode;
export { renderCommentsIcon };
