/**
 * @prettier
 */

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Comment from '../Comment';

import makeRequest from '../../../lib/makeRequest';

function Loading() {
	return (
		<div>
			<div className="readingEnvLoadingHeader" />
			<div className="readingEnvLoadingLine" />
			<div className="readingEnvLoadingLine" />
			<div className="readingEnvLoadingLine" />
			<div className="readingEnvLoadingLine" />
			<div className="readingEnvLoadingLine" />
		</div>
	);
}

function SidePanelCommentary({
	openCommentForm,
	refetchComments,
	setCurrentComment,
	urn,
	work,
}) {
	const [comments, setComments] = useState([]);
	const [commentsLoading, setCommentsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [next, setNext] = useState(null);
	const removeComment = id => {
		setComments(comments.filter(c => c.id !== id));
		refetchComments();
	};

	const getComments = useCallback(async () => {
		setCommentsLoading(true);
		const data = await makeRequest(`comments/?urn_search=${urn}`);

		if (data) {
			setComments(data.results);
			setNext(data.next);
		} else {
			console.error('Error fetching comments. Check the network tab.');
		}

		setCommentsLoading(false);
	}, [urn]);

	const getMore = useCallback(async () => {
		if (!next) return;

		setCommentsLoading(true);

		const response = await fetch(next);

		if (response.ok) {
			const data = await response.json();
			setError(null);
			setComments((comments || []).concat(data.results));
			setNext(data.next);
		} else {
			setError(
				'There was an error getting those comments. Try again in a moment, and check the network tab for more details.',
				response
			);
		}

		setCommentsLoading(false);
	}, [next]);

	useEffect(() => {
		getComments();
	}, [urn]);

	if (error) {
		return <p className="error p4">{error}</p>;
	}

	return (
		<div>
			{comments.length === 0 && !commentsLoading ? (
				<div className="p4">We don&apos;t have any comments yet.</div>
			) : (
				comments.map((c, i) => (
					<Comment
						comment={c}
						key={`${c.id}-${i}`}
						openCommentForm={openCommentForm}
						removeComment={removeComment}
						setCurrentComment={setCurrentComment}
						work={work}
					/>
				))
			)}
			{commentsLoading && <Loading />}
			{next && (
				<a
					className="bold brown underline alexandriaButton -light -outline"
					href="#"
					onClick={getMore}
					style={{ margin: '0 auto' }}
				>
					Load more
				</a>
			)}
		</div>
	);
}

SidePanelCommentary.propTypes = {
	openCommentForm: PropTypes.func,
	refetchComments: PropTypes.func,
	setCurrentComment: PropTypes.func,
	urn: PropTypes.string,
	work: PropTypes.object,
};

export default SidePanelCommentary;
