import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import Cover from '../Cover';


const expressionsOfWoe = [
  'ἰὼ ἰώ',
  'αἶ, αἶ',
  'οἴμοι μοι',
  'φεῦ φεῦ',
  'ἰώ μοί μοι',
  'ὦ Ζεῦ',
  'βοᾷ βοᾷ',
  'αἰαῖ αἰαῖ',
  'ἔα ἔα',
  'ὀττοτοτοτοτοῖ',
  'ἄλγος ἄλγος βοᾷς',
  'ἐλελεῦ',
  'μὴ γένοιτο',
  'οὐαί'
];

const NotFound = props => {
	const leadContent = [];
  // hack spacing into this to display with default cover component
	leadContent.push(<br />);
  leadContent.push('Invoke a ');
  leadContent.push(<a href="mailto:outreach@chs.harvard.edu">website muse</a>);
  leadContent.push(' to steer you back on course or make your own odyssey ');
	leadContent.push(<a href="/">back to the home page.</a>);
  let randomExpression = expressionsOfWoe[Math.floor(Math.random() * expressionsOfWoe.length)];

	return (
		<div className="notFound">

			<Cover
        title={`${randomExpression}, hero!`}
				imageUrl="/wp-content/themes/orpheus/static/images/sisyphus.gif"
				lead={leadContent}
        subtitle="You have lost your way."
				center
			/>

		</div>
	);
};

export default NotFound;
