/**
 * @prettier
 */

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
	ContentState,
	convertFromHTML,
	convertFromRaw,
	EditorState,
} from 'draft-js';
import moment from 'moment';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import ShareIcon from '@material-ui/icons/Share';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Popover from '@material-ui/core/Popover';

import CommentTags from './CommentTags';
import CHSEditor from '../../../orpheus-editor/components/Editor';
import decorators from '../../../orpheus-editor/components/decorators';

import canEditComments from '../../lib/canEditComments';
import makeRequest from '../../lib/makeRequest';
import markCitations from '../../lib/markCitations';

const deleteComment = async id =>
	makeRequest(`comments/${id}/`, {
		method: 'DELETE',
	});

Comment.propTypes = {
	comment: PropTypes.shape({
		id: PropTypes.number.isRequired,
		revisions: PropTypes.array.isRequired,
		urn: PropTypes.string.isRequired,
	}),
	highlight: PropTypes.bool,
	openCommentForm: PropTypes.func.isRequired,
	removeComment: PropTypes.func,
	setCurrentComment: PropTypes.func.isRequired,
};

function Comment({
	comment,
	openCommentForm,
	removeComment,
	setCurrentComment,
}) {
	const [tags, setTags] = useState(comment.tags);
	const _editComment = useCallback(
		e => {
			setCurrentComment(comment);
			openCommentForm(e);
		},
		[comment.id]
	);

	const _deleteComment = useCallback(
		async e => {
			e.preventDefault();
			e.stopPropagation();

			if (window.confirm('Are you sure you want to delete this comment?')) {
				const id = comment.id;
				const resp = await deleteComment(id);

				if (resp) {
					removeComment(id);
				}
			}
		},
		[comment.id]
	);

	const _shareComment = useCallback(
		e => {
			// todo share comment;
			console.log(comment);
		},
		[comment.id]
	);

	const _openRevisionsMenu = useCallback(
		e => {
			// todo comment revisions;
			console.log(comment);
		},
		[comment.id]
	);

	const removeTag = useCallback(
		tagName => {
			setTags(tags.filter(t => t.name !== tagName));
		},
		[comment.id]
	);

	// const revision = comment.revisions[0];
	const [revision, setRevision] = useState(comment.revisions[0]);
	if (!revision) {
		return null;
	}

	const urn = comment.urn;

	// mark citations in text
	// can be added to orpheus editor with predictive citation model in the future
	// FIXME: markCitations()
	// needs to be done in a decorator, otherwise it breaks the JSON parsing
	// of the raw text and causes the JSON to be rendered as a string.
	// const revisionText = markCitations(revision.text);

	let baseContent;
	try {
		baseContent = convertFromRaw(JSON.parse(revision.text));
	} catch (e) {
		// if we've failed to parse the JSON, we can assume that markCitations should be safe
		const { contentBlocks, entityMap } = convertFromHTML(
			markCitations(revision.text)
		);
		baseContent = ContentState.createFromBlockArray(contentBlocks, entityMap);
	}

	const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const popoverOpen = Boolean(anchorEl);

	return (
		<div
			className="comment"
			key={comment.id}
			data-commentid={comment.id}
			data-urn={urn}
		>
			<div className="commentHead">
				<div className="commentHeadText">
					{revision.title ? <h2>{revision.title}</h2> : ''}
				</div>
				<div className="commentHeadMeta">
					<span className="commentByline">
						{comment.commenters && comment.commenters.length ?
							<a href={`/commenters/${comment.commenters[0].username}`} className="commenterLink">
								{comment.commenters[0].picture ?
									<div
										className="chipAvatar"
										style={{
											backgroundImage:
												`url(${comment.commenters[0].picture})`,
										}}
									/>
								: ''}
								<span>{`${comment.commenters[0].first_name} ${comment.commenters[0].last_name}`}</span>
							</a>
						: ''}
						{moment(revision.created_at).format('D MMM Y')}
					</span>
					<div className="commentActions flex">
						{/*
						<span
							role="button"
							className="dark-gray hover-opaque ml2 mr2 muted pointer"
							onClick={_shareComment}
						>
							<ShareIcon />
						</span>
						*/}
						{canEditComments() ? (
							<>
								<span
									role="button"
									className="dark-gray hover-opaque muted pointer"
									onClick={_editComment}
								>
									<EditIcon />
								</span>
								<span
									role="button"
									className="dark-gray hover-opaque ml1 muted pointer"
									onClick={_deleteComment}
								>
									<DeleteOutlineIcon />
								</span>
							</>
						) : null}
						<span
							role="button"
							className="dark-gray hover-opaque ml1 muted pointer"
							onClick={handleOpenPopover}
						>
							<MoreHorizIcon />
						</span>
						<Popover
							id="revision_popover"
							open={popoverOpen}
							anchorEl={anchorEl}
							onClose={handleClosePopover}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'center',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'center',
							}}
						>
							{comment.revisions.map((revision, i) => (
								<button
									key={revision.created_at}
									className="revisionSelectButton p1 "
									onClick={setRevision.bind(this, revision)}
								>
									{moment(revision.created_at).format('D MMM Y')}
								</button>
							))}
						</Popover>
					</div>
				</div>
			</div>
			<div className="flex">
				<div className="commentBody">
					<CHSEditor
						editorState={EditorState.createWithContent(baseContent, decorators)}
						readOnly
					/>
				</div>
			</div>
			<CommentTags
				commentId={comment.id}
				removeTag={removeTag}
				tags={tags || []}
			/>
		</div>
	);
}

export default Comment;
