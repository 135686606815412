import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const userUpdatePosition = gql`
	mutation userUpdatePosition($position: PositionInputType!) {
	userUpdatePosition(position: $position) {
		_id
	}
}
 `;

const userUpdatePositionMutation = graphql(userUpdatePosition, {
	props: params => ({
		userUpdatePosition: position => params.userUpdatePositionMutation({
			variables: {
				position,
			},
		}),
	}),
	name: 'userUpdatePositionMutation',
});


export {
	userUpdatePositionMutation,
};
