/**
 * @prettier
 */

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import AddCommentButton from './AddCommentButton';
import Navigation from './Navigation';

import canEditComments from '../../lib/canEditComments';

// we can't just look for non-word characters,
// because we often have Greek text
const WORD_SEPARATOR_REGEX = /\s|\.|,|_|\-|\(|\)/;

/**
 *
 * @param {string} string
 * @param {number} offset
 * @returns {string} whole word
 */
const getWholeWord = (string = '', offset) => {
	let wordStart = offset;
	let wordEnd = offset;

	while (!WORD_SEPARATOR_REGEX.test(string[wordStart - 1]) && wordStart > 0) {
		wordStart = wordStart - 1;
	}

	while (
		!WORD_SEPARATOR_REGEX.test(string[wordEnd]) &&
		wordEnd < string.length
	) {
		wordEnd = wordEnd + 1;
	}

	return [string.slice(wordStart, wordEnd), wordStart, wordEnd];
};

const HEADINGS = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'];

const isHeadingSelected = selection => {
	try {
		return (
			HEADINGS.some(h => selection.anchorNode.parentNode.closest(h)) ||
			HEADINGS.some(h => selection.focusNode.parentNode.closest(h))
		);
	} catch (e) {
		console.error(e);

		return false;
	}
};

const isSelectionBackward = (
	anchorLocation = [],
	anchorOffset = 0,
	focusLocation = [],
	focusOffset = 0
) => {
	const isDeepEqual = anchorLocation.reduce(
		(memo, n, i) => memo && n === focusLocation[i],
		true
	);

	if (isDeepEqual) {
		// if the focus offset comes before the anchor
		// offset within the same textNode, the selection
		// is backwards
		return focusOffset < anchorOffset;
	}

	// if any of the focusLocations is smaller than the
	// corresponding anchorLocation, the selection is
	// backwards
	return focusLocation.some((n, i) => n < anchorLocation[i]);
};

const openSidePanelForSelection = (fn, selection) => {
	if (!selection || selection.isCollapsed) return null;

	const { anchorNode, anchorOffset, focusNode, focusOffset } = selection;
	// find the nearest node (including self) that has data-location
	// for anchor and focus nodes
	const anchorParent = anchorNode.parentNode.closest('div[data-location]');
	const focusParent = focusNode.parentNode.closest('div[data-location]');

	if (!anchorParent || !focusParent) return null;

	const anchorData = anchorParent.dataset;
	const focusData = focusParent.dataset;
	const anchorLocation = JSON.parse(anchorData.location);
	const focusLocation = JSON.parse(focusData.location);
	const anchorUrn = anchorData.urn;
	const focusUrn = focusData.urn;
	const anchorString = anchorNode.data;
	const focusString = focusNode.data;

	// these ternaries allow selecting a single letter
	const [anchorWord, anchorStart, anchorEnd] =
		selection.toString().length > 1
			? getWholeWord(anchorString, anchorOffset)
			: [anchorWord, anchorOffset, anchorOffset];
	const [focusWord, focusStart, focusEnd] =
		selection.toString().length > 1
			? getWholeWord(focusString, focusOffset)
			: [focusWord, focusOffset, focusOffset];
	// technically, subrefs should be words, but the API expects
	// Ints for subrefs. Further, we allow comments on single
	// characters, so a subref needs to use the offset index,
	// otherwise it won't be able to identify the correct character.
	const anchorSubref = `@${anchorWord}`;
	const focusSubref = `@${focusWord}`;
	const isBackward = isSelectionBackward(
		anchorLocation,
		anchorOffset,
		focusLocation,
		focusOffset
	);

	const baseAndExtent = isBackward
		? [focusNode, focusStart, anchorNode, anchorEnd]
		: [anchorNode, anchorStart, focusNode, focusEnd];
	selection.setBaseAndExtent.apply(selection, baseAndExtent);

	let urn = '';
	if (focusUrn === anchorUrn && focusWord === anchorWord) {
		urn = `${anchorUrn}${anchorSubref}`;
	} else {
		urn = isBackward
			? `${focusUrn}${focusSubref}-${anchorLocation.join('.')}${anchorSubref}`
			: `${anchorUrn}${anchorSubref}-${focusLocation.join('.')}${focusSubref}`;
	}

	return e => {
		e.preventDefault();
		fn(urn, selection.toString());
	};
};

const Reader = ({
	children,
	openSidePanelForUrn,
	sidePanelOpen,
	text,
	textNodes,
	urn,
}) => {
	const readingEnvRef = useRef();
	const [buttonAnchor, setButtonAnchor] = useState(null);
	const [selection, setSelection] = useState(null);

	const handleMouseUp = _e => {
		// wait for the selection state to update
		window.requestAnimationFrame(() => {
			const selection = window.getSelection();

			if (selection.isCollapsed || isHeadingSelected(selection)) {
				setButtonAnchor(null);
				setSelection(null);
				return;
			}

			setButtonAnchor(selection.anchorNode.parentElement);
			setSelection(selection);
		});
	};

	const isButtonShown = Boolean(
		buttonAnchor && canEditComments()
	);

	return (
		<div>
			<Header text={text} textNodes={textNodes} />
			<Navigation
				endIndex={textNodes.length && textNodes[textNodes.length - 1].index}
				startIndex={(textNodes.length && textNodes[0].index) || 0}
				urn={urn}
			>
				<div
					className="relative z1"
					id="@@reading-env/text"
					onMouseUp={handleMouseUp}
					ref={readingEnvRef}
				>
					<AddCommentButton
						onClick={openSidePanelForSelection(openSidePanelForUrn, selection)}
						shown={isButtonShown}
						targetRef={readingEnvRef}
					/>
					{children}
				</div>
			</Navigation>
		</div>
	);
};

Reader.defaultProps = {
	text: {},
	textNodes: [],
};

Reader.propTypes = {
	children: PropTypes.node,
	openSidePanelForUrn: PropTypes.func,
	sidePanelOpen: PropTypes.bool,
	text: PropTypes.shape({
		id: PropTypes.string,
		title: PropTypes.string,
	}),
	textNodes: PropTypes.arrayOf(
		PropTypes.shape({
			location: PropTypes.array,
			urn: PropTypes.string,
		})
	),
	urn: PropTypes.string,
};

export default Reader;
