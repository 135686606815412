import React from 'react'
import autoBind from 'react-autobind';
import {
	CompositeDecorator,
	KeyBindingUtil,
} from 'draft-js'


const cleanUrl = s => {
	if (!/^https?/.test(s)) {
		return `https://${s}`;
	}

	return s;
};

class Link extends React.Component {
	constructor(props) {
		super(props);

		const { contentState, entityKey } = props;

		this.data = contentState.getEntity(entityKey).getData();

		autoBind(this);
	}

	handleMouseDown(e) {
		if (KeyBindingUtil.hasCommandModifier(e)) {
			e.preventDefault();

			window.open(cleanUrl(this.data.url), "_blank");
		}
	}

	render() {
		return (
			<a
				href={cleanUrl(this.data.url)}
				className="link"
				onMouseDown={this.handleMouseDown}
				rel="noopener noreferrer"
				target="_blank"
			>
				{this.props.children}
			</a>
		);
	}
}

const findLinkEntities = (
	contentBlock,
	callback,
	contentState
) => {
	contentBlock.findEntityRanges(
		(character) => {
			const entityKey = character.getEntity();

			if (!!entityKey) {
				return contentState.getEntity(entityKey).getType() === 'LINK'
			}

			return false;
		},
		callback,
	);
}

const linkDecorator = new CompositeDecorator([{
	strategy: findLinkEntities,
	component: Link,
}]);

export default linkDecorator;
export { Link, findLinkEntities };
