/**
 * @prettier
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import cn from 'classnames';
import { getVisibleSelectionRect } from 'draft-js';

// components
import FormattingTooltipButtons from '../FormattingTooltipButtons';

import editorActions from '../../../../actions';

import './FormattingTooltip.css';

class FormattingTooltip extends React.Component {
  static propTypes = {
    editorRef: PropTypes.object.isRequired,
    editorState: PropTypes.object.isRequired,
    formattingTooltipContent: PropTypes.func,
    setEditorState: PropTypes.func.isRequired,
    setFormattingTooltipContent: PropTypes.func.isRequired,
  };

  state = {
    left: 0,
    top: 0,
  };

  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidUpdate(prevProps) {
    const { editorState } = this.props;

    const prevSelection = prevProps.editorState.getSelection();
    const selection = editorState.getSelection();
    const selectionChanged = !prevSelection.equals(selection);

    if (selectionChanged) {
      setTimeout(this._showTooltip, 0);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { editorState, formattingTooltipContent } = this.props;
    const { left, top } = this.state;

    if (
      !nextProps.editorState !== editorState ||
      formattingTooltipContent !== nextProps.formattingTooltipContent ||
      nextState.left !== left ||
      nextState.top !== top
    ) {
      return true;
    }

    return false;
  }

  getContent() {
    return this.props.formattingTooltipContent || FormattingTooltipButtons;
  }

  // don't close the tooltip when it's clicked
  onMouseDown(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  overrideContent(content) {
    this.props.setFormattingTooltipContent(content);
  }

  render() {
    const { editorRef, editorState, setEditorState } = this.props;
    const { left, top } = this.state;
    const selection = editorState.getSelection();
    const hasSelection =
      (!selection.isCollapsed() && selection.getHasFocus()) ||
      this.props.formattingTooltipContent;
    const Content = this.getContent();
    const className = cn('formattingTooltip', {
      formattingTooltipVisible: hasSelection,
    });

    return (
      <div
        className={className}
        onMouseDown={this.onMouseDown}
        style={{ left, top }}
      >
        <Content
          editorState={editorState}
          editorRef={editorRef}
          overrideContent={this.overrideContent}
          setEditorState={setEditorState}
        />
      </div>
    );
  }

  _getTooltipPosition(selectionRect) {
    const { editorRef } = this.props;
    const position = Object.assign({}, this.state.position);
    const editorRoot = editorRef.current.editorContainer.parentNode;
    const editorRootRect = editorRoot.getBoundingClientRect();
    const toolbarHeight = 60;

    position.top =
      editorRoot.offsetTop -
      toolbarHeight +
      (selectionRect.top - editorRootRect.top);

    position.left =
      editorRoot.offsetLeft +
      (selectionRect.left - editorRootRect.left) +
      selectionRect.width / 2;

    return position;
  }

  _showTooltip() {
    const selectionRect = getVisibleSelectionRect(window);

    if (!selectionRect) return;

    this.setState({
      ...this._getTooltipPosition(selectionRect),
    });
  }
}

export default FormattingTooltip;
