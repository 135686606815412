/**
 * @prettier
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { parseValueUrn as parseUrn } from '../../../lib/parseUrn';

import textWorksQuery from '../../../graphql/queries/textWorks';

function Navigation({ children, startIndex, endIndex, urn }) {
	const {
		ctsNamespace,
		exemplar,
		textGroup,
		translation,
		version,
		work,
	} = parseUrn(urn);
	urn = `urn:cts:${ctsNamespace}:${textGroup}.${work}.${exemplar ||
		version ||
		translation}`;

	const { data, error, loading } = useQuery(textWorksQuery, {
		variables: {
			endIndex,
			offset: 30,
			startIndex,
			urn,
		},
	});

	if (loading) {
		return children;
	}

	if (data) {
		let textLocationNext = [];
		let textLocationPrev = [];
		const { works: tw = [] } = data;
		if (tw.length) {
			textLocationNext = tw[0].textLocationNext;
			textLocationPrev = tw[0].textLocationPrev;
		}

		return (
			<>
				{error && <p className="error">{error.toString()}</p>}
				{children}
				<div className="readingEnvNavigation">
					{(textLocationPrev && textLocationPrev.length && (
						<div className="p0 textLocationOuter textLocationPrev">
							<Link to={`${urn}:${textLocationPrev.join('.')}`}>
								<button className="alexandriaButton -light -outline">
									<i className="mdi mdi-chevron-left" />
									&nbsp;&nbsp;Previous
								</button>
							</Link>
						</div>
					)) ||
						null}
					{(textLocationNext && textLocationNext.length && (
						<div className="p0 textLocationOuter textLocationNext">
							<Link to={`${urn}:${textLocationNext.join('.')}`}>
								<button className="alexandriaButton -light -outline">
									Next&nbsp;&nbsp;
									<i className="mdi mdi-chevron-right" />
								</button>
							</Link>
						</div>
					)) ||
						null}
				</div>
			</>
		);
	}

	return null;
}

export default Navigation;
