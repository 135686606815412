import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import autobind from 'react-autobind';

import IconButton from 'material-ui/IconButton';
import FontIcon from 'material-ui/FontIcon';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import muiTheme from '../../lib/muiTheme';
import Utils from '../../lib/utils';
import AvatarIcon from '../AvatarIcon';
import AnnotationIconToggleContainer from '../../containers/AnnotationIconToggleContainer';

/*
 * The annotations toggle to the right of a paragraph that toggles the
 * display of the annotations on the page
 * Returns state back to the main component Home.js
 */

class AnnotationIcon extends React.Component {

	constructor(props) {
		super(props);

		autobind(this);
	}

	getChildContext = () => {
		return { muiTheme: getMuiTheme(muiTheme) };
	}

	makeIconLabel = () => {
		let label = '';
		const users = this.props.usersGetPublicById.usersGetPublicById;

		if (users && users.length) {
			label = Utils.getUserName(users[0]);

			if (users.length > 2) {
				label += `, ${Utils.getUserName(users[1])}, and ${users.length - 1} other`;
			} else if (users.length === 2 && Utils.getUserName(users[1]) === "Richard Martin" && Utils.getUserName(users[0]) === "Ewen Bowie") {
				label = "Richard Martin and Ewen Bowie"
			} else if (users.length === 2) {
				label += ` and ${Utils.getUserName(users[1])}`;
			}
		}

		return (
			<span>{label}</span>
		);
	}

	render = () => {
		const { paragraph, paragraphIsActive, paragraphIsBookmarked, openAnnotationDrawer, toggleBookmark, loggedInUser, userAuthenticatedToAnnotate, userIds } = this.props;
		let xAdjust = 20;

		if (window.innerWidth < 720) {
			xAdjust = -10;
		}

		const styles = {
			annotationIconWrapper: {
				top: (paragraph.yOffset + 14),
				left: (paragraph.xOffset + paragraph.width + xAdjust),
			},
			createAnnotationBookmarkIcons: {
				opacity: 0,
			},
			createAnnotationBookmarkIconButton: {
				padding: '8px 0',
			},
		};

		const colors = {
			iconColor: '#999',
			iconHoverColor: '#666',
			bookmarkedIconColor: '#702E3D',
			bookmarkedIconHoverColor: '#55212d',
		};

		if (paragraphIsActive) {
			styles.createAnnotationBookmarkIcons.opacity = 1;
		}

		let legacyMode = Cookies.get('legacyMode');

		if (legacyMode === 'true') {
			legacyMode = true;
		} else {
			legacyMode = false;
		}

		if (legacyMode) {
			return null;
		}

		return (
			<div
				className="annotationIconWrapper"
				style={styles.annotationIconWrapper}
			>
				{(userIds && userIds.length) ?
					<AnnotationIconToggleContainer
						userIds={userIds}
						openAnnotationDrawer={openAnnotationDrawer}
						paragraph={paragraph}
					/>
				: ''}
				{loggedInUser ?
					<div>
						<div>
							<div
								className="createAnnotationBookmarkIcons"
								style={styles.createAnnotationBookmarkIcons}
							>
								{userAuthenticatedToAnnotate ?
									<div className="createAnnotationBookmarkIconOuter">
										<IconButton
											tooltip="Create Annotation"
											className="createAnnotationBookmarkIconButton"
											style={styles.createAnnotationBookmarkIconButton}
											onClick={openAnnotationDrawer.bind(this, paragraph, true)}
										>
											<FontIcon
												className="mdi mdi-pencil createAnnotationBookmarkIcon"
												color={colors.iconColor}
												hoverColor={colors.iconHoverColor}
											/>
										</IconButton>
									</div>
								: ''}
								<div className="createAnnotationBookmarkIconOuter">
									{paragraphIsBookmarked ?
										<IconButton
											tooltip="Remove Bookmark"
											className="createAnnotationBookmarkIconButton"
											style={styles.createAnnotationBookmarkIconButton}
											onClick={toggleBookmark.bind(this, paragraph)}
										>
											<FontIcon
												className="mdi mdi-bookmark createAnnotationBookmarkIcon"
												color={colors.bookmarkedIconColor}
												hoverColor={colors.bookmarkedIconHoverColor}
											/>
										</IconButton>
									:
										<IconButton
											tooltip="Bookmark"
											className="createAnnotationBookmarkIconButton"
											style={styles.createAnnotationBookmarkIconButton}
											onClick={toggleBookmark.bind(this, paragraph)}
										>
											<FontIcon
												className="mdi mdi-bookmark-outline createAnnotationBookmarkIcon"
												color={colors.iconColor}
												hoverColor={colors.iconHoverColor}
											/>
										</IconButton>
									}
								</div>
							</div>
						</div>
					</div>
				: ''}
			</div>
		);
	}
}

AnnotationIcon.childContextTypes = {
	muiTheme: PropTypes.object,
};

AnnotationIcon.propTypes = {
	paragraph: PropTypes.object,
	users: PropTypes.array,
	paragraphIsActive: PropTypes.bool,
	paragraphIsBookmarked: PropTypes.bool,
	openAnnotationDrawer: PropTypes.func,
	toggleBookmark: PropTypes.func,
	loggedInUser: PropTypes.object,
	userAuthenticatedToAnnotate: PropTypes.bool,
};

export default AnnotationIcon;
