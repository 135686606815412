/**
 * @prettier
 */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Set } from 'immutable';

import canEditComments from '../../lib/canEditComments';
import makeRequest from '../../lib/makeRequest';

function Tag({ commentId, name, removeTag }) {
	const deleteTag = useCallback(
		e => {
			e.preventDefault();
			e.stopPropagation();

			makeRequest(`comments/${commentId}/remove_tag/?name=${name}`, {
				method: 'DELETE',
			});
			removeTag(name);
		},
		[commentId, name]
	);

	return (
		<div className="bg-gray-lighter br gray-darker inline-block commentTag">
			{canEditComments() ? (
				<a className="mt1 pointer" onClick={deleteTag}>
					{name}
					&nbsp; &times;
				</a>
			) : (
				<span>{name}</span>
			)}
		</div>
	);
}

Tag.propTypes = {
	commentId: PropTypes.number,
	name: PropTypes.string,
	removeTag: PropTypes.func,
};

function CommentTags({ commentId, removeTag, tags }) {
	return (
		<div className="commentTags">
			{tags.map((tag = {}, i) => {
				if (tag && tag.name) {
					const { name } = tag;

					return (
						<Tag
							commentId={commentId}
							key={`${name}-${i}`}
							name={name}
							removeTag={removeTag}
						/>
					);
				}
				return null;
			})}
		</div>
	);
}

CommentTags.propTypes = {
	commentId: PropTypes.number,
	removeTag: PropTypes.func,
	tags: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(Set)]),
};

export default CommentTags;
