/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './FormattingTooltipItemButton.css';

FormattingTooltipItemButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

const onMouseDown = evt => {
  evt.preventDefault();
  evt.stopPropagation();
};

function FormattingTooltipItemButton({ children, className, onClick }) {
  return (
    <button
      className={cn('formattingTooltipItemButton', className)}
      onClick={onClick}
      onMouseDown={onMouseDown}
      type="button"
    >
      {children}
    </button>
  );
}

export default FormattingTooltipItemButton;
