/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
	ContentState,
	convertFromHTML,
	convertFromRaw,
	EditorState,
} from 'draft-js';

import SidePanelAbout from './SidePanelAbout';
import SidePanelCommentary from './SidePanelCommentary';
import SidePanelHeader from './SidePanelHeader';
import SidePanelText from './SidePanelText';

import CHSEditor from '../../../../orpheus-editor/components/Editor';
import decorators from '../../../../orpheus-editor/components/decorators';

import deriveLocationFromUrn from '../../../lib/deriveLocationFromUrn';
import getUrnFromUrl from '../../../lib/getUrnFromUrl';

const createEditorState = (userTextNode = { revisions: [] }) => {
	const latestRevision = userTextNode.revisions[0] || {};

	const { text } = latestRevision;

	if (!text) return null;

	let baseContent = null;

	try {
		baseContent = convertFromRaw(JSON.parse(text));
	} catch (e) {
		const { contentBlocks, entityMap } = convertFromHTML(
			`<span>${Boolean(text) ? text : ''}</span>`
		);
		baseContent = ContentState.createFromBlockArray(contentBlocks, entityMap);
	}

	return EditorState.createWithContent(baseContent, decorators);
};

const renderUserTextNodes = (nodes = []) => {
	return nodes
		.map((n, i) => {
			const location = n.location || deriveLocationFromUrn(n.urn);
			const urn = n.urn;
			const editorState = createEditorState(n);

			if (!editorState) return null;

			return (
				<div
					className="flex hide-child"
					key={`${n.urn}-${i}`}
					style={{ fontSize: '1.25rem', lineHeight: '1.6em' }}
				>
					<div
						className="child ease-in-out fw7 gray-dark left pointer unselectable"
						style={{ fontSize: 16, maxWidth: 70, width: 70 }}
					>
						{location.join('.')}
					</div>
					<div
						data-urn={urn}
						data-location={JSON.stringify(location)}
						style={{ flexGrow: 1 }}
					>
						<CHSEditor editorState={editorState} readOnly />
					</div>
				</div>
			);
		})
		.filter(n => n !== null);
};

function SidePanelTranslation({ urn }) {
	const [userTexts, setUserTexts] = useState([]);
	const [userTextsError, setUserTextsError] = useState(null);
	const [userTextsLoading, setUserTextsLoading] = useState(true);

	useEffect(() => {
		fetch(
			`/api/usertexts/?urn_search=${encodeURIComponent(
				urn
			)}&content_type=translation`
		)
			.then(res => res.json())
			.then(res => setUserTexts(res.results))
			.catch(err => setUserTextsError(err))
			.finally(() => setUserTextsLoading(false));
	}, []);

	if (userTextsLoading) {
		return <div>Loading translation...</div>;
	}

	if (userTextsError) {
		return (
			<div>
				<span className="error">
					Sorry, we encountered an error. {userTextsError.message}
				</span>
			</div>
		);
	}

	return <div>{renderUserTextNodes(userTexts)}</div>;
}

SidePanelTranslation.propTypes = {
	urn: PropTypes.string,
};

const renderCurrentSelection = ({
	currentSelection,
	baseUrn,
	fullUrn,
	focusedUrn,
	work,
	refetchComments,
	setCurrentComment,
	openCommentForm,
}) => {
	if (!currentSelection) {
		if (focusedUrn) {
			return (
				<SidePanelCommentary
					openCommentForm={openCommentForm}
					refetchComments={refetchComments}
					setCurrentComment={setCurrentComment}
					urn={focusedUrn}
					work={work}
				/>
			);
		}

		return null;
	}

	if (currentSelection === '@@reading-env/about-the-text') {
		return <SidePanelAbout urn={baseUrn} />;
	}

	if (currentSelection === '@@reading-env/userText-translation') {
		return <SidePanelTranslation urn={baseUrn} />;
	}

	/** FIXME: (charles) This isn't a great way to handle work IDs,
	 * but it should work for now.
	 */
	if (typeof currentSelection === 'number') {
		return <SidePanelText id={currentSelection} work={work} />;
	}

	if (currentSelection === '@@reading-env/project-commentary') {
		return (
			<SidePanelCommentary
				openCommentForm={openCommentForm}
				setCurrentComment={setCurrentComment}
				urn={getUrnFromUrl() || fullUrn}
				work={work}
			/>
		);
	}

	try {
		const nodes = JSON.parse(currentSelection);

		if (nodes && nodes.length) {
			return renderUserTextNodes(nodes);
		}
	} catch (e) {
		console.error(e);
	}
};

const SidePanelContent = ({
	focusedUrn,
	openCommentForm,
	projectId,
	refetchComments,
	setCurrentComment,
	text,
}) => {
	const [currentSelection, setCurrentSelection] = useState('');
	const { work } = text;
	const { full_urn: fullUrn, urn: baseUrn } = work;

	useEffect(() => {
		if (focusedUrn) {
			setCurrentSelection('');
		}
	}, [focusedUrn]);

	return (
		<div className="sidePanelContentContainer px3">
			<div className="sidePanelHeader z5">
				<SidePanelHeader
					currentSelection={currentSelection}
					projectId={projectId}
					setCurrentSelection={setCurrentSelection}
					urn={baseUrn}
				/>
			</div>
			<div className="sidePanelContent hide-scrollbars">
				{renderCurrentSelection({
					currentSelection,
					baseUrn,
					fullUrn,
					focusedUrn,
					work,
					refetchComments,
					setCurrentComment,
					openCommentForm,
				})}
			</div>
		</div>
	);
};

SidePanelContent.propTypes = {
	focusedUrn: PropTypes.string,
	openCommentForm: PropTypes.func,
	projectId: PropTypes.string,
	refetchComments: PropTypes.func,
	setCurrentComment: PropTypes.func,
	text: PropTypes.shape({ work: PropTypes.shape({ urn: PropTypes.string }) }),
};

export default SidePanelContent;
