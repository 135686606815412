import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const annotationUpdate = gql`
	mutation annotationUpdate($_id: String! $annotation: CommentInputType!) {
	annotationUpdate(id: $_id annotation: $annotation) {
		_id
	}
}
 `;

const annotationRemove = gql`
	mutation annotationRemove($id: String!) {
	annotationRemove(id: $id) {
		_id
	}
}
 `;

const annotationCreate = gql`
mutation annotationCreate($annotation: CommentInputType!) {
	annotationCreate(annotation: $annotation) {
    _id
    urn
		users
    bookChapterUrl
    paragraphN
    parentCommentId
    keywords
    revisions
    created
    updated
	}
}
 `;

const annotationAddRevision = gql`
mutation annotationAddRevision($id: String!, $revision: RevisionInputType!) {
	annotationAddRevision(id: $id, revision: $revision) {
    _id
    urn
		users
    bookChapterUrl
    paragraphN
    parentCommentId
    keywords
    revisions
    created
    updated
	}
}
 `;

const annotationRemoveMutation = graphql(annotationRemove, {
	props: params => ({
		annotationRemove: id => params.annotationRemoveMutation({
			variables: {
				id,
			},
		}),
	}),
	name: 'annotationRemoveMutation',
	options: {
		refetchQueries: ['annotationQuery'],
	},
});

const annotationUpdateMutation = graphql(annotationUpdate, {
	props: params => ({
		annotationUpdate: (_id, annotation) => params.annotationUpdateMutation({
			variables: {
				_id,
				annotation,
			},
		}),
	}),
	name: 'annotationUpdateMutation',
	options: {
		refetchQueries: ['annotationQuery'],
	},
});

const annotationCreateMutation = graphql(annotationCreate, {
	props: params => ({
		annotationCreate: annotation => params.annotationCreateMutation({
			variables: {
				annotation,
			},
		}),
	}),
	name: 'annotationCreateMutation',
	options: {
		refetchQueries: ['annotationQuery'],
	},
});

const annotationAddRevisionMutation = graphql(annotationAddRevision, {
	props: params => ({
		annotationAddRevision: (id, revision) => params.annotationAddRevisionMutation({
			variables: {
				id,
				revision,
			},
		}),
	}),
	name: 'annotationAddRevisionMutation',
	options: {
		refetchQueries: ['annotationQuery'],
	},
});

export {
	annotationRemoveMutation, annotationUpdateMutation, annotationCreateMutation,
	annotationAddRevisionMutation,
};
