import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import getCurrentPathname from '../../lib/getCurrentPathname';


const query = gql`
	query annotationQuery($bookChapterUrl: String!) {
		annotations(bookChapterUrl: $bookChapterUrl) {
	    _id
	    urn
			users
	    bookChapterUrl
	    paragraphN
	    parentCommentId
	    keywords
	    revisions
	    created
	    updated
		}
	}
`;

const annotationQuery = graphql(query, {
	name: 'annotationQuery',
	options: {
		variables: {
			bookChapterUrl: getCurrentPathname(),
		},
	},
});

const annotationRemove = gql`
	mutation annotationRemove($id: String!) {
		annotationRemove(annotationId: $id) {
			_id
		}
	}
`;

const annotationRemoveMutation = graphql(annotationRemove, {
	props: params => ({
		annotationRemove: id => params.annotationRemoveMutation({variables: {id}}),
	}),
	name: 'annotationRemoveMutation',
	options: {
		refetchQueries: ['annotationQuery']
	}
});

export { annotationQuery, annotationRemoveMutation };
