const getCurrentArchiveHostname = (hostname = '') => {

	if (
			window.location.hostname.includes('alexandria')
	) {

		if (
				window.location.hostname === "alexandria.staging.archimedes.digital"
			|| window.location.hostname === "alexandria.local"
		) {
			return '';
		}
		else {
			return window.location.hostname.split('.')[0];
		}
	}

	const orpheusHostnames = ['staging.orphe.us', 'staging.orpheus.local', 'orphe.us', 'orpheus.local', 'localhost'];

	if (window.location.hostname.includes("app.")) {

		return window.location.hostname.replace('app.', '');
	}


	if (
		window
		&& window.location.hostname
		&& !~orpheusHostnames.indexOf(window.location.hostname)
	) {
		hostname = window.location.hostname;
	}

	if (window.location.hostname === 'localhost' && window.location.port === '9009') {
		hostname = 'beyondwords.orphe.us';
	}



	// regularlize development domain
	if (hostname && hostname.endsWith('orpheus.local')) {
		hostname = hostname.replace('orpheus.local', 'orphe.us');
	}

	// beta subdomain for testing production tenants
	hostname = hostname.replace('.beta', '')
	hostname = hostname.replace('.staging', '')

	return hostname;
};

export default getCurrentArchiveHostname;
