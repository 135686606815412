/**
 * @prettier
 */

import Cookies from 'universal-cookie';

const cookies = new Cookies();
const csrfToken = cookies.get('csrftoken');

const _handleResponse = async response => {
	let error;

	if (response.status === 204) {
		return true;
	}

	try {
		const result = await response.json();

		if (response.ok) {
			return result;
		}

		error = result;
	} catch (e) {
		error = e;
	}

	console.error('There was an error with that request', error);

	return null;
};

/**
 *
 * @param {string} path - the path to the API resource. Should not start with a /.
 * @param {*} opts - options for the fetch request. See https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
 */
export default async function makeRequest(path = '', opts = {}) {
	if (path.indexOf('/') === 0) {
		console.warn(
			'`path` argument to `makeRequest()` should not start with a /.'
		);

		path = path.slice(1);
	}

	let headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'X-CSRFToken': csrfToken,
	};

	if (opts.headers) {
		headers = {
			...headers,
			...opts.headers,
		};

		delete opts.headers;
	}

	const response = await fetch(`/api/${path}`, {
		credentials: 'include',
		headers,
		...opts,
	});

	return _handleResponse(response);
}
