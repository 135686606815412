const getCurrentLocationSpan = (textNodes = []) => {
	const firstNode = textNodes[0];
	const lastNode = textNodes[textNodes.length - 1];

	if (!firstNode) return [];

	const firstLocation = firstNode.location;
	const lastLocation = lastNode.location;

	if (!firstLocation) return [];

	return [firstLocation.join('.'), lastLocation.join('.')];
};

export default getCurrentLocationSpan;