/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import _ from 'underscore';
import _s from 'underscore.string';

import Card from '../../../../components/common/cards/Card';
import CardLoadingItem from '../../../../components/common/cards/CardLoadingItem';
import formatItemDataForCard from '../../../../lib/formatItemDataForCard';
import itemEmbedQuery from '../../graphql/queries/embed';

const formatDescription = raw => {
  let parsed;

  try {
    parsed = JSON.parse(raw) || { blocks: [] };
  } catch (e) {
    console.error(`Unable to parse description: ${raw}`);

    return null;
  }

  return parsed.blocks.map(b => b.text).join(' ');
};

class ItemEmbedContainer extends React.Component {
  static propTypes = {
    hostname: PropTypes.string,
    itemEmbedQuery: PropTypes.shape({
      loading: PropTypes.bool,
      project: PropTypes.shape({
        item: PropTypes.shape({
          description: PropTypes.string,
          title: PropTypes.string,
        }),
      }),
    }),
    itemId: PropTypes.string,
  };

  render() {
    let card;
    let item = null;
    let loading = false;

    if (this.props.itemEmbedQuery) {
      loading = this.props.itemEmbedQuery.loading;
      if (this.props.itemEmbedQuery.project) {
        item = this.props.itemEmbedQuery.project.item;
      }
    }

    if (loading) {
      return <CardLoadingItem />;
    }

    if (!item) {
      console.error('No item found for', this.props.itemEmbedQuery);
      return null;
    }

    const {
      itemUrl,
      metadata,
      files,
      file,
      imageUrl,
      mediaUrl,
    } = formatItemDataForCard(item);
    const description = formatDescription(item.description);

    if (imageUrl) {
      card = (
        <Card
          to={itemUrl}
          key={_s.slugify(item.title)}
          className="listItemCard"
          title={item.title}
          textShort={_s.prune(description, 270)}
          assetImageSrc={imageUrl}
          assetImageWidth={1000}
          assetImageAlt={item.title} // is that correct?
          metadata={metadata}
        />
      );
    } else if (mediaUrl) {
      // TODO: media url
      card = (
        <Card
          to={itemUrl}
          key={_s.slugify(item.title)}
          className="listItemCard"
          title={item.title}
          textShort={_s.prune(description, 270)}
          assetVideoSrc={mediaUrl}
          metadata={metadata}
        />
      );
    } else if (item.commenter) {
      card = (
        <Card
          to={itemUrl}
          key={_s.slugify(item.title)}
          className="listItemCard"
          title={item.title}
          textLong={_s.prune(item.body, 500)}
          metadata={metadata}
          footer={{
            authorURL: `/@${item.commenter.slug}`,
            authorName: item.commenter.name,
            authorImageURL: item.commenter.image,
            postedDate: item.updated,
          }}
        />
      );
    } else if (item.user) {
      // TODO: user
    } else {
      card = (
        <Card
          to={itemUrl}
          key={_s.slugify(item.title)}
          className="listItemCard"
          title={item.title}
          textLong={_s.prune(description, 1500)}
          metadata={metadata}
        />
      );
    }

    return card;
  }
}

ItemEmbedContainer.propTypes = {
  itemEmbedQuery: PropTypes.object,
};

export default compose(itemEmbedQuery)(ItemEmbedContainer);
