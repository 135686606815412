/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import TextNode from '../TextNode';

import { parseValueUrn as parseUrn } from '../../../lib/parseUrn';
import getHeadings from '../../../lib/getHeadings';

// $urn can include citations and subrefs
const SIDE_PANEL_TEXT_NODES_QUERY = gql`
	query textNodesQuery(
		$id: Int!
		$startsAtLocation: [Int]
		$endsAtLocation: [Int]
	) {
		work(id: $id) {
			full_urn
			id
			refsDecls {
				label
			}
			urn
			textNodes(
				startsAtLocation: $startsAtLocation
				endsAtLocation: $endsAtLocation
			) {
				id
				index
				location
				text
				urn
			}
		}
	}
`;

const noop = () => {};

const SidePanelText = ({ id }) => {
	const urnFromUrl = window.location.pathname.split('/')[2].split(/\?|\#/)[0];
	const passage = (parseUrn(urnFromUrl) || {}).passage;

	let startsAtLocation = null;
	let endsAtLocation = null;
	if (passage && passage.length) {
		startsAtLocation = passage[0];
		if (passage.length > 1) {
			endsAtLocation = passage[1];
		}
	}
	const { loading, error, data } = useQuery(SIDE_PANEL_TEXT_NODES_QUERY, {
		variables: {
			id,
			endsAtLocation,
			startsAtLocation,
		},
	});

	if (error || loading) {
		return (
			<div className="p4">
				{error && (
					<span className="error">
						Sorry, we encountered an error. {error.message}
					</span>
				)}
				{loading && <span>Loading text...</span>}
			</div>
		);
	}

	const { work = {} } = data;
	const { textNodes = [] } = work;

	if (!textNodes.length) {
		return (
			<div className="p4">
				<span>Sorry, we didn&apos;t find anything for work {id}</span>
			</div>
		);
	}

	if ((startsAtLocation || []).join('.') !== textNodes[0].location.join('.')) {
		return (
			<div className="p4">
				<span>Sorry, we don&apos;t have any results for this passage.</span>
			</div>
		);
	}

	const { refsDecls } = work;
	const text = textNodes.map((textNode, i) => {
		const prevNode = textNodes[i - 1];
		const prevLocation = (prevNode && prevNode.location) || [];
		const { location } = textNode;

		const { heading, subheading, subsubheading } = getHeadings(
			location,
			prevLocation,
			refsDecls
		);

		return (
			<div key={`div-${textNode.urn}-${textNode.location}`}>
				{heading}
				{subheading}
				{subsubheading}
				<TextNode
					hideInteractiveButtons
					key={`${textNode.urn}-${textNode.location}`}
					openCommentForm={noop}
					openSidePanel={noop}
					openSidePanelForUrn={noop}
					selection={null}
					setCurrentComment={noop}
					textNode={textNode}
				/>
			</div>
		);
	});

	return <div>{text}</div>;
};

SidePanelText.propTypes = {
	id: PropTypes.number,
	work: PropTypes.shape({ refsDecls: PropTypes.array }),
};

export default SidePanelText;
