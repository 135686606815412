/**
 * @prettier
 */

import gql from 'graphql-tag';

const query = gql`
	query tableOfContentsQuery($fullUrn: String!) {
		works(full_urn: $fullUrn) {
			refsDecls {
				label
				slug
			}
			tableOfContent {
				index
				label
				children
			}
		}
	}
`;

export default query;
