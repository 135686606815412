import _s from 'underscore.string';

const formatItemDataForCard = (item) => {
	const itemUrl = `/issues/${item.name}/${item.slug}`;
	const metadata = [];
	let files = [];
	let file;
	let imageUrl;
	let audioSrc;
	let videoSrc;
	let pdfSrc;
	let mediaUrl;

	if (item.files && item.files.length) {
		files = item.files;
	}

	if (files.length) {
		file = files[0];
		const fileType = file.type || '';
		const isImage = fileType.slice(0, fileType.indexOf('/')) === 'image';

		if (isImage) {
			imageUrl = file.path; // `//iiif.orphe.us/${file.name}/full/full/0/default.jpg`;
		} else {
			// TODO: handle media
			if (file.type.indexOf("audio") >= 0) {
				audioSrc = file.path; // `https://s3.amazonaws.com/iiif-orpheus/${file.name}`;
			} else if (file.type.indexOf("video") >= 0){
				videoSrc = file.path; // `https://s3.amazonaws.com/iiif-orpheus/${file.name}`;
			} else if (file.type.indexOf("pdf") >= 0){
				pdfSrc = file.path; // `https://s3.amazonaws.com/iiif-orpheus/${file.name}`;
			} else {
				mediaUrl = file.path; // `https://s3.amazonaws.com/iiif-orpheus/${file.name}`;
			}
		}
	}

	if (item.metadata && item.metadata.length) {
		item.metadata.forEach(datum => {
			if (datum.type.indexOf('text', 'number') >= 0) {
				metadata.push({
					type: datum.label,
					metaLabel: datum.label,
					metaValue: datum.value,
				});
			}
		});
	}

	return {
		itemUrl,
		metadata,
		files,
		file,
		imageUrl,
		audioSrc,
		videoSrc,
		pdfSrc,
		mediaUrl,
	};
};

export default formatItemDataForCard;
