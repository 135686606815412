import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import moment from 'moment';

import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import linkifyHtml from 'linkifyjs/html';

import muiTheme from '../../lib/muiTheme';
import Utils from '../../lib/utils';
import AnnotationCreateContainer from '../../containers/AnnotationCreateContainer';
import AvatarIcon from '../AvatarIcon';


class Annotation extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			editMode: false,
			showCreateReply: false,
			activeRevision: this.props.revisions[this.props.revisions.length - 1],
			revisionsDropdownOpen: false,
		};

		autoBind(this);
	}


	getChildContext = () => {
		return { muiTheme: getMuiTheme(muiTheme) };
	}

	showEdit = () => {
		this.setState({
			editMode: true,
			showCreateReply: false,
		});
	}

	closeEditMode = () => {
		console.log('closeEditMode');
		this.setState({
			editMode: false,
			showCreateReply: false,
		});
	}

	cancelEdit = () => {
		this.setState({
			editMode: false,
		});
	}

	showReply = () => {
		this.setState({
			showCreateReply: true,
			editMode: false,
		});
	}

	closeReply = () => {
		this.setState({
			showCreateReply: false,
		});
	}

	handleRevisionsTouchTap = (event) => {
		// This prevents ghost click.
		event.preventDefault();

		this.setState({
			revisionsDropdownOpen: true,
			anchorEl: event.currentTarget,
		});
	}

	selectRevision = (revision) => {
		this.setState({
			activeRevision: revision,
			revisionsDropdownOpen: false,
		});
	}

	handleRequestClose = () => {
		this.setState({
			revisionsDropdownOpen: false,
		});
	}

	removeAnnotation = (_id) => {
		this.props.annotationRemove(_id);
	}

	render = () => {
		const {
			_id, paragraphN, revisions, parentCommentId, toggleUserModal, hasReply,
			book,
		} = this.props;
		const { activeRevision, editMode, showCreateReply } = this.state;

		const loggedInUser = this.props.getAuthedUserQuery.getAuthedUser;
		const users = this.props.usersGetPublicById.usersGetPublicById;

		if (!users) {
			return null;
		}

		// TODO: add nested queries to graphql so we don't to proxy the annotations
		// user query through the usersGetPublicById
		const user = users[0];

		let annotationIsReply = false;
		let annotationIsEditable = false;
		let userIsAuthor = false;

		if (parentCommentId) {
			annotationIsReply = true;
		}

		users.forEach((_user) => {
			if (!hasReply && loggedInUser && loggedInUser._id === _user._id) {
				annotationIsEditable = true;
			}
		});

		if (
				user
			&& book
			&& user.authorOfBooks
			&& ~user.authorOfBooks.indexOf(book._id)
		) {
			userIsAuthor = true;
		}

		return (
			<div className={`annotation ${annotationIsReply ? 'annotation--reply' : ''}`}>
				<div className="annotationContent">
					{editMode ?
						<AnnotationCreateContainer
							paragraphN={paragraphN}
							closeEditMode={this.closeEditMode.bind(this)}
							handleCancel={this.cancelEdit.bind(this)}
							revision={activeRevision}
							annotationId={_id}
						/>
					:
						<div
							className="annotationBody"
						>
							<div className="annotationUpper">
								<div className="annotationUser">
									<a
										href={`//profile.${Utils.getEnvDomain()}/users/${user ? user._id : ''}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										<AvatarIcon
											className="avatarIcon"
											avatar={user ? user.profile.avatarUrl : ''}
											isAuthor={userIsAuthor}
										/>
										<span className="name">
											{Utils.getUserName(user)}
											{userIsAuthor ?
												<em>Author</em>
											: ''}
										</span>
									</a>
								</div>
								{revisions.length > 1 ?
									<div
										className="revisions"
										onTouchTap={this.handleRevisionsTouchTap.bind(this)}
									>
										<span className="revisionsActiveLabel">
											{moment(activeRevision.created).format('D MMM YYYY')}
										</span>
										<i className="mdi mdi-dots-vertical revisionsToggle" />
									</div>
								:
									<div
										className="revisions revisionsNoHover"
									>
										<span className="revisionsActiveLabel">
											{moment(activeRevision.created).format('D MMM YYYY')}
										</span>
									</div>
								}
								<Popover
									open={this.state.revisionsDropdownOpen}
									anchorEl={this.state.anchorEl}
									anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
									targetOrigin={{horizontal: 'left', vertical: 'top'}}
									onRequestClose={this.handleRequestClose}
								>
									<Menu>
										{revisions.map((revision, i) => (
											<MenuItem
												key={i}
												primaryText={moment(activeRevision.created).format('D MMM YYYY')}
												onClick={this.selectRevision.bind(this, revision)}
											/>
										))}
									</Menu>
								</Popover>
							</div>
							<div
								className="annotationText"
								dangerouslySetInnerHTML={{__html: linkifyHtml(activeRevision.text)}}
							/>
							{annotationIsEditable ?
								<div className="annotationActions">
									<button
										className="annotationAction annotationActionEdit"
										onClick={this.showEdit.bind(this)}
									>
										Edit
									</button>
									<button
										className="annotationAction annotationActionDelete"
										onClick={this.removeAnnotation.bind(this, this.props._id)}
									>
										Delete
									</button>
								</div>
							: ''}
						</div>
					}
				</div>
				{!annotationIsReply ?
					<div>
						{showCreateReply ?
							<AnnotationCreateContainer
								paragraphN={paragraphN}
								closeReply={this.closeReply.bind(this)}
								annotationId={_id}
								closeEditMode={this.closeEditMode.bind(this)}
								isReply
							/>
						:
							<button
								className="replyToggle"
								onClick={
									loggedInUser ?
										this.showReply.bind(this)
									:
										this.props.toggleUserModal.bind(this, true)
								}
							>
								<span className="replyLabel">Reply</span>
							</button>
						}
					</div>
				: ''}
			</div>
		);
	}
}

Annotation.propTypes = {
	_id: PropTypes.string,
	isAnnotation: PropTypes.bool,
	hasReply: PropTypes.bool,
	users: PropTypes.array,
	paragraphN: PropTypes.number,
	book: PropTypes.object,
	bookChapterUrl: PropTypes.string,
	parentCommentId: PropTypes.string,
	revisions: PropTypes.array,
	saveAnnotation: PropTypes.func,
	loggedInUser: PropTypes.object,
	toggleUserModal: PropTypes.func,
};

Annotation.childContextTypes = {
	muiTheme: PropTypes.object.isRequired,
};

export default Annotation;
