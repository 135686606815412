import React from 'react';
import Cookies from 'js-cookie';

import RecentList from '../RecentList';
import Utils from '../../lib/utils';
import getCurrentPathname from '../../lib/getCurrentPathname';

class UserDropdown extends React.Component {

	signOut = () => {
		const domain = Utils.getEnvDomain();
		Cookies.remove('userId', { domain });
		Cookies.remove('loginToken', { domain });
		location.reload();
	}

	render = () => {
		const user = this.props.loggedInUser;
		const username = Utils.getUserName(user);
		let recentItems = [];

		if (user && user.recentPositions) {
			const itemsToRemove = [];
			recentItems = user.recentPositions.slice();

			// remove recent positions with the same link as location.pathname
			recentItems.forEach((recentItem, i) => {
				if (recentItem.link === getCurrentPathname()) {
					itemsToRemove.push(i);
				}
			});
			itemsToRemove.forEach((itemIndex) => {
				recentItems.splice(itemIndex, 1);
			});

			// only display two most recent items
			if (recentItems.length >= 2) {
				recentItems = recentItems.slice(recentItems.length - 2, recentItems.length);
			}
		}

		return (
			<div className="userDropdown paperShadow">
				<div className="userDropdownLeft">
					<h4>Keep Reading</h4>
					<RecentList recentItems={recentItems} />
				</div>
				<div className="userDropdownRight">
					<a href={`http://profile.${Utils.getEnvDomain()}/profile`}>{Utils.trunc(username, 16)}</a>
					<a href={`http://profile.${Utils.getEnvDomain()}/`}>Community</a>
					<a href={`http://profile.${Utils.getEnvDomain()}/about`}>About</a>
					<a href="mailto:muellner@chs.harvard.edu">Contact</a>
					<a href={`http://profile.${Utils.getEnvDomain()}/terms`}>Terms</a>
					<a
						onClick={this.signOut}
					>
						Sign out
					</a>
				</div>
			</div>
		);
	}
}

export default UserDropdown;
