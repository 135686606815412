/**
 * @prettier
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { convertToRaw } from 'draft-js';
import { Set } from 'immutable';

import CommentTags from '../CommentTags';
import CHSEditor from '../../../../orpheus-editor/components/Editor';
import decorators from '../../../../orpheus-editor/components/decorators';

import createBaseEditorState from '../../../lib/createBaseEditorState';
import makeRequest from '../../../lib/makeRequest';

const _createComment = async c =>
	makeRequest('comments/', {
		body: JSON.stringify(c),
		method: 'POST',
	});

const _createHashtag = async s =>
	makeRequest(`tags/`, {
		body: JSON.stringify({ name: s }),
		method: 'POST',
	});

const _updateComment = async c =>
	makeRequest(`comments/${c.id}/`, {
		body: JSON.stringify(c),
		method: 'PUT',
	});

const _getTagSuggestions = async s => makeRequest(`tags/?q=${s}`);

const CommentForm = ({
	comment = {},
	commentTarget,
	hideCommentForm,
	projectId,
	refetchComments,
	urn,
}) => {
	// https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
	const revision =
		comment.revisions && comment.revisions.length ? comment.revisions[0] : {};
	const [error, setError] = useState(null);
	const [localContent, setLocalContent] = useState(
		createBaseEditorState(revision, decorators)
	);
	const [localTitle, setLocalTitle] = useState(revision.title || '');
	const [submitting, setSubmitting] = useState(false);
	const [success, setSuccess] = useState(null);
	const [tags, setTags] = useState(Set(comment.tags || []));

	useEffect(() => {
		if (success) {
			hideCommentForm();
		}

		if (error) {
			setSubmitting(false);
		}
	}, [error, success]);

	const createComment = async (title, text) => {
		const revision = {
			text,
			title,
		};

		const comment = {
			revisions: [revision],
			tags,
			urn,
		};

		const response = await _createComment(comment);

		return response;
	};

	const createHashtag = async s => {
		const tag = await _createHashtag(s);

		if (tag) {
			setTags(tags.add(tag));
		}
	};

	const updateComment = async (title, text) => {
		const created_at = new Date();
		const revision = {
			created_at,
			text,
			text_raw: null,
			title,
		};
		const updatedComment = Object.assign({}, comment, {
			created_at,
			revisions: [revision],
			tags,
			text,
			title,
			updated_at: created_at,
		});

		const response = await _updateComment(updatedComment);

		return response;
	};

	const _handleCancelClick = e => {
		e.preventDefault();
		e.stopPropagation();

		hideCommentForm();
	};

	const handleSubmit = async e => {
		e.preventDefault();

		setSubmitting(true);

		let content;
		try {
			content = JSON.stringify(convertToRaw(localContent.getCurrentContent()));
		} catch (e) {
			console.error('Could not convert content to raw. Reason:', e);
			return;
		}

		// if the comment has already been saved on the
		// server, update it
		const ok = Boolean(comment.id)
			? await updateComment(localTitle, content)
			: await createComment(localTitle, content);

		setSubmitting(false);

		if (ok) {
			setSuccess(true);
			await refetchComments();
		} else {
			setError(
				new Error(
					'We encountered an error making that change. Please try again in a moment.'
				)
			);
		}
	};
	const handleTitleChange = e => setLocalTitle(e.target.value);

	// ensure comment target isn't too long to display
	let _commentTarget = commentTarget;
	if (_commentTarget.length > 50) {
		_commentTarget = `${_commentTarget.slice(0, 30)} . . . ${_commentTarget.slice(_commentTarget.length - 10, commentTarget.length)}`;
	}

	return (
		<div className="p1">
			<h3>{Boolean(comment.id) ? 'Edit Comment' : 'Create Comment'}</h3>
			<p className="gray focusText unselectable commentLemma">{_commentTarget}</p>
			{error && <p className="error">{error.toString()}</p>}
			<form onSubmit={handleSubmit}>
				<div className="mb2">
					<input
						className="commentTitleInput border-0 f4 inherit-font m0 mb2 p0 w-100"
						onChange={handleTitleChange}
						placeholder="Title"
						value={localTitle}
					/>
					<CHSEditor
						editorState={localContent}
						enableInlineMedia
						getAutoCompleteSuggestions={_getTagSuggestions}
						handleChange={setLocalContent}
						handleSelectHashtag={createHashtag}
					/>
					<CommentTags tags={tags} />
				</div>
				<div className="sidebarFormActionButtons">
					<Button
						variant="contained"
						color="primary"
						onClick={handleSubmit}
						disabled={submitting}
					>
						{submitting ? ' Saving...' : 'Save'}
					</Button>
					<Button onClick={_handleCancelClick}>Cancel</Button>
				</div>
			</form>
		</div>
	);
};

CommentForm.propTypes = {
	comment: PropTypes.shape({
		_id: PropTypes.string,
		latestRevision: PropTypes.shape({
			originalDate: PropTypes.string,
			text: PropTypes.string,
			title: PropTypes.string,
		}),
	}),
	commentTarget: PropTypes.string,
	hideCommentForm: PropTypes.func,
	projectId: PropTypes.string,
	refetchComments: PropTypes.func,
	urn: PropTypes.string,
};

export default CommentForm;
