import React from 'react'
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';

// redux
import editorActions from '../../../../actions';

// components
import AddImageButton from './AddImageButton';
import AddDividerButton from './AddDividerButton';
import AddItemButton from './AddItemButton';
// import AddSourceTextButton from './AddSourceTextButton';
import AddYoutubeButton from './AddYoutubeButton';
import AddVimeoButton from './AddVimeoButton';
import AddSketchfabButton from './AddSketchfabButton';

import './AddTooltipMenu.css';


class AddTooltipMenu extends React.Component {
  static propTypes = {
    closeMenu: PropTypes.func.isRequired,
    visible: PropTypes.bool,
  };

	constructor(props) {
    super(props)
		autoBind(this);
  }

	render() {
		const { closeMenu, visible } = this.props;

		return (
			<div
				 className={`
					 addTooltipMenu
					 ${visible ? 'addTooltipMenuVisible' : ''}
				 `}
			 >
				<div className="addTooltipMenuSection">
					<h6 className="addTooltipMenuSectionHeader">Primary</h6>
					<div className="addTooltipMenuSectionItems">
						<AddImageButton closeMenu={closeMenu} />
						<AddDividerButton closeMenu={closeMenu} />
						<AddItemButton closeMenu={closeMenu} />
						{/*<AddSourceTextButton closeMenu={closeMenu} />*/}
					</div>
				</div>
				<div className="addTooltipMenuSection">
					<h6 className="addTooltipMenuSectionHeader">Embed</h6>
					<div className="addTooltipMenuSectionItems">
						<AddYoutubeButton closeMenu={closeMenu} />
						<AddVimeoButton closeMenu={closeMenu} />
						<AddSketchfabButton closeMenu={closeMenu} />
					</div>
				</div>
				<div className="addTooltipMenuFooter">
					More options coming soon.
				</div>
			</div>
		);
	}
}

export default AddTooltipMenu;
