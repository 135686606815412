/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import Button from '@material-ui/core/Button';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutline';
import TextField from '@material-ui/core/TextField';

const cookies = new Cookies();
const csrfToken = cookies.get('csrftoken');

const fetchBookmarks = cb =>
	fetch(`/bookmarks/`, {
		credentials: 'include',
	})
		.then(resp => resp.json())
		.then(json => {
			cb(json.bookmarks.map(b => ({ ...b.fields, id: b.pk })) || []);
		})
		.catch(reason => {
			console.error(reason);
			cb([]);
		});

const renderBookmarks = (bookmarks = [], setBookmarks) => {
	if (bookmarks.length === 0) return <h6>No bookmarks found</h6>;

	const handleDelete = id => e => {
		e.preventDefault();
		e.stopPropagation();

		fetch(`${process.env.REACT_ENV_V2_SERVER}/bookmarks/${id}/`, {
			credentials: 'include',
			headers: { 'X-CSRFToken': csrfToken },
			method: 'DELETE',
		}).then(resp => {
			if (resp.ok) {
				return setBookmarks(bookmarks.filter(b => b.id !== id));
			}
		});
	};

	return (
		<ul>
			{bookmarks.map(b => {
				return (
					<li key={b.id}>
						<a href={`/read/${b.urn}`}>{b.title || b.urn}</a>
						<span className="ml1" onClick={handleDelete(b.id)}>
							<DeleteOutlinedIcon />
						</span>
					</li>
				);
			})}
		</ul>
	);
};

export default function BookmarkMenu({ urn }) {
	if (!urn) return null;

	const [bookmarks, setBookmarks] = useState([]);
	const [title, setTitle] = useState(urn);

	useEffect(() => {
		fetchBookmarks(setBookmarks);
	}, []);

	const handleChange = e => setTitle(e.target.value);
	const saveBookmark = e => {
		e.preventDefault();

		fetch('/bookmarks/', {
			credentials: 'include',
			body: JSON.stringify({
				title,
				urn,
			}),
			headers: { 'Content-Type': 'application/json', 'X-CSRFToken': csrfToken },
			method: 'post',
		})
			.then(resp => {
				if (resp.ok) {
					return fetchBookmarks(setBookmarks);
				}

				return resp.text();
			})
			.then(text => console.warn('Unable to save bookmark: ', text))
			.catch(reason => {
				console.error(reason);
			});
	};

	return (
		<div className="px2 bookmarkMenu readingEnvMenu">
			<h3>Bookmarks</h3>
			{renderBookmarks(bookmarks, setBookmarks)}
			<form>
				<TextField
					fullWidth
					label="Add a bookmark"
					onChange={handleChange}
					size="small"
					value={title}
					variant="outlined"
				/>
				<button className="alexandriaButton" onClick={saveBookmark}>
					Add
				</button>
			</form>
		</div>
	);
}

BookmarkMenu.propTypes = {
	urn: PropTypes.string,
};
