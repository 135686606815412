import * as React from 'react';
import { CompositeDecorator } from 'draft-js';

const Image = props => {
	const { alt, src } = props.contentState.getEntity(props.entityKey).getData();

	return <img src={src} alt={alt || ''} />;
};

const findImageEntities = (contentBlock, callback, contentState) => {
	contentBlock.findEntityRanges(
		(character) => {
			const entityKey = character.getEntity();

			return (
				entityKey !== null &&
				contentState.getEntity(entityKey).getType() === 'IMAGE'
			);
		},
		callback,
	);
};

const ImageDecorator = new CompositeDecorator([{
	component: Image,
	strategy: findImageEntities,
}]);

export default ImageDecorator;
export { Image, findImageEntities };
