/**
 * @prettier
 */

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import './ImageBlock.css';

const MAX_HEIGHT = 500;
const MAX_WIDTH = 500;

export default class ImageBlock extends React.Component {
	state = {
		loaded: false,
	};

	constructor(props) {
		super(props);

		this.imageRef = React.createRef();
	}

	componentDidMount() {
		this.mounted = true;

		let { block, src } = this.props;

		// support for legacy embedded images
		// these will probably crash the editor
		// if they're ever deleted during an edit,
		// but they should be fine to display
		if (!src && block) {
			try {
				src = block.getData().toJS().url;
			} catch (e) {
				console.warn('Unable to get embedded image source.\n', e);

				return this.setState({
					error: true,
					loaded: true,
				});
			}
		}

		this._fetchImage(src);
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	render() {
		return (
			<div className="imageWithCaption">
				<div
					className="aspectRatioPlaceholder is-locked"
					style={{
						maxHeight: `${MAX_HEIGHT}px`,
						maxWidth: `${MAX_WIDTH}px`,
					}}
				>
					{this._renderImageOrPlaceholder()}
				</div>
			</div>
		);
	}

	_fetchImage(src) {
		const img = new Image();

		img.src = src;

		img.onerror = evt => {
			console.log(`There was a problem loading the image at ${src}.\n${evt}`);

			if (!this.mounted) return null;

			this.setState({
				error: evt.error || 'Image error',
			});
		};

		img.onload = () => {
			// we're checking whether the component is mounted
			// here in case the response starts before unmount
			// but ends after
			if (!this.mounted) return null;

			const { height, width } = this._getAspectRatio(img);

			// we must set the properties with dot notation
			// because `img` is not an object but an HTMLImageElement
			img.alt = '';
			img.height = height;
			img.width = width;

			this.setState(
				{
					loaded: true,
				},
				() => {
					this.imageRef.current.appendChild(img);
				}
			);
		};
	}

	_getAspectRatio({ naturalHeight: height, naturalWidth: width }) {
		let ratio = 0;

		// shrink the image horizontally first, preserving aspect ratio
		if (width > MAX_WIDTH) {
			ratio = MAX_WIDTH / width;
			height = height * ratio;
			width = width * ratio;
		}

		if (height > MAX_HEIGHT) {
			ratio = MAX_HEIGHT / height;
			width = width * ratio;
			height = height * ratio;
		}

		return { height, width };
	}

	_renderImageOrPlaceholder() {
		const { error, loaded } = this.state;

		if (error) return null;

		if (loaded) {
			return <div ref={this.imageRef} />;
		}

		return <CircularProgress />;
	}
}
