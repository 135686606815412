import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import Utils from './lib/utils';
import ReadingEnvironmentContainer from './containers/ReadingEnvironmentContainer';
import client from './middleware/apolloClient';
import configureStore from './store/configureStore';


const store = configureStore();

class Annotations extends React.Component {
	componentWillMount = () => {
		// Check if browser supports retina
		Utils.checkIsRetina();
	}

	render  = () => {
		console.log('v2.0.1');
		return (
			<ApolloProvider
				client={client}
				store={store}
			>
				<MuiThemeProvider>
					<ReadingEnvironmentContainer />
				</MuiThemeProvider>
			</ApolloProvider>
		);
	}
}

export default Annotations;
