import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import muiTheme from '../../lib/muiTheme';

class TableOfContents extends React.Component {
	static propTypes = {
		book: PropTypes.object,
	}

	getChildContext = () => {
		return {muiTheme: getMuiTheme(muiTheme)};
	}

	render = () => {
		const { book } = this.props;
		const style = {
			whiteSpace: 'initial', // ensures longer lines are wrapped
			padding: '12px 0px',
			lineHeight: '26px',
			minHeight: 'inherit'
		};

		return (
			<div>
				{book.chapters.map((chapter, i) => (
					<MenuItem
						key={i}
						style={style}
						href={chapter.url}
					>
						{chapter.title}
					</MenuItem>
				))}
			</div>
		);
	}
}

TableOfContents.childContextTypes = {
	muiTheme: PropTypes.object,
};

export default TableOfContents;
