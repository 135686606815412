import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import $ from 'jquery';


import muiTheme from '../../lib/muiTheme';
import Utils from '../../lib/utils';
import ddpclient from '../../middleware/ddp';
import AvatarIcon from '../AvatarIcon';
import UserDropdown from '../UserDropdown';
import ReadingDrawer from '../ReadingDrawer';
import AnnotationsToggle from '../AnnotationsToggle';
import getCurrentPathname from '../../lib/getCurrentPathname';


class Header extends React.Component {
	static propTypes = {
		book: PropTypes.object,
		tenant: PropTypes.object,
		loggedInUser: PropTypes.object,
		openUserModal: PropTypes.func,
	}

	constructor(props) {
		super(props);
		const navDepth = $('header').offset().top;

		/* let headerVisible = false;

		if ($(window).scrollTop() > navDepth) {
			headerVisible = true;
		}
		*/

		this.state = {
			open: false,
			drawerPublications: false,
			langaugeNotAvailableVisible: false,
			showUserDropdown: false,
			headerVisible: true,
			navDepth,
		};

		autobind(this);
	}

	getChildContext = () => {
		return { muiTheme: getMuiTheme(muiTheme) };
	}

	componentDidMount = () => {
		window.addEventListener('scroll', this.handleScroll.bind(this));
		window.addEventListener('resize', this.setNavDepth.bind(this));
	}

	setNavDepth = () => {
		this.setState({
			navDepth: $('header').offset().top,
		});
	}

	handleScroll = () => {
		/**
		const { headerVisible, navDepth } = this.state;
		const scrollDepth = $(window).scrollTop();

		if (scrollDepth > navDepth) {
			if (!headerVisible) {
				this.setState({
					headerVisible: true,
				});
			}
		} else if (headerVisible) {
			this.setState({
				headerVisible: false,
			});
		}
		*/
	}

	handleToggle = () => {
		const open = this.state.open;
		if (open) {
			this.setState({ drawerPublications: false });
		}
		this.setState({ open: !open });
	}

	togglePublications = () => {
		this.setState({ drawerPublications: !this.state.drawerPublications });
	}

	mouseOverLanguageNotAvailable = () => {
		this.setState({
			languageNotAvailableVisible: true,
		});
	}

	mouseOutLanguageNotAvailable = () => {
		this.setState({
			languageNotAvailableVisible: false,
		});
	}

	showUserDropdown = () => {
		if (!this.state.showUserDropdown) {
			this.setState({
				showUserDropdown: true,
			});
		}
	}

	hideUserDropdown = () => {
		if (this.state.showUserDropdown) {
			this.setState({
				showUserDropdown: false,
			});
		}
	}

	render = () => {
		const { book, tenant, loggedInUser } = this.props;
		const { headerVisible, languageNotAvailableVisible, showUserDropdown } = this.state;
		let activeChapter;

		if (!headerVisible || !book) {
			return null;
		}

		book.chapters.forEach((chapter) => {
			if (chapter.url === getCurrentPathname()) {
				activeChapter = chapter;
			}
		});

		return (
			<div>
				<header
					className="header"
				>
					<div
						onClick={this.handleToggle.bind(this)}
						className="headerToggle"
					>
						<i
							className="menuToggle mdi mdi-menu"
						/>
						{activeChapter ?
							<span
								className="readingLocation"
							>
								{activeChapter.title}
							</span>
						: ''}
					</div>
					<div className="headerRightButtons">
						<div className="languagesToggle">
							<a
								className="language languageActive"
							>
								English
							</a>
							|
							<a
								className="language languageDisabled"
								onMouseOver={this.mouseOverLanguageNotAvailable.bind(this)}
								onMouseOut={this.mouseOutLanguageNotAvailable.bind(this)}
							>
								Eλληνικά
								<span
									className={`languageNotAvailable
									${languageNotAvailableVisible ? 'languageNotAvailableVisible' : ''}
								`}
								>
									Language not available for this text.
								</span>
							</a>
						</div>
						{loggedInUser ?
							<div
								className="profileButton"
								onMouseOver={this.showUserDropdown}
								onMouseLeave={this.hideUserDropdown}
							>
								<div
									className="profileButtonAvatar"
								>
									<a
										href={`//profile.${Utils.getEnvDomain()}/profile`}
										target="_blank"
										rel="noopener noreferrer"
									>
										<AvatarIcon
											className="avatarIcon"
											avatar={'profile' in loggedInUser ? loggedInUser.profile.avatarUrl : ''}
										/>
									</a>
								</div>
								{showUserDropdown ?
									<UserDropdown
										loggedInUser={loggedInUser}
									/>
								: ''}
							</div>
						:
							<div className="authenticationToggle">
								<a
									className="authenticationOption authenticationOptionLogin"
									onClick={this.props.openUserModal.bind(this, true)}
								>
									Login
								</a>
								<a
									className="authenticationOption authenticationOptionSignup"
									onClick={this.props.openUserModal.bind(this, false)}
								>
									Sign up
								</a>
							</div>
						}
						{/*
							<AnnotationsToggle />
						*/}
					</div>
				</header>
				<ReadingDrawer
					isOpen={this.state.open}
					handleToggle={this.handleToggle}
					book={book}
					tenant={tenant}
					drawerPublications={this.state.drawerPublications}
					togglePublications={this.togglePublications}
				/>
			</div>
		);
	}
}

Header.childContextTypes = {
	muiTheme: PropTypes.object,
};

export default Header;
