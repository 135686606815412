import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import getCurrentArchiveHostname from '../../../../lib/getCurrentArchiveHostname';


const query = gql`
	query itemEmbedQuery($hostname: String, $id: String) {
		project(hostname: $hostname) {
	    _id
			title
			userIsAdmin
			tags
			item(_id: $id) {
				_id
				title
				slug
				description
				projectId
				tags

				metadata {
					type
					label
					value
				}

				files {
					_id
					name
					title
					type
					path
				}

				commentsCount
				comments {
					_id
					userId
					itemId
					content
					updatedAt
				}

				manifest {
					_id
				}
			}
		}
	}
`;

const itemEmbedQuery = graphql(query, {
	name: 'itemEmbedQuery',
	options: (props) => {
		return {
			variables: {
				hostname: getCurrentArchiveHostname(props.hostname),
				id: props.itemId,
			},
		};
	},
});

export default itemEmbedQuery;
