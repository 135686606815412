/**
 * @prettier
 */

import { AtomicBlockUtils, EditorState, Modifier } from 'draft-js';
import getCurrentBlock from './getCurrentBlock';

export default (
	editorState,
	{ entityType = 'ITEM', entityMutability = 'IMMUTABLE', entityData = {} }
) => {
	let newContentState = editorState
		.getCurrentContent()
		.createEntity(entityType, entityMutability, entityData);
	const entityKey = newContentState.getLastCreatedEntityKey();

	newContentState = Modifier.replaceText(
		newContentState,
		editorState.getSelection().merge({
			anchorOffset: 0,
			focusOffset: getCurrentBlock(editorState).getCharacterList().size,
		}),
		''
	);

	const newEditorState = EditorState.push(
		editorState,
		newContentState,
		'apply-entity'
	);

	return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
};
